import React, {useEffect, useState} from "react";
import {Archivo, DocumentNames, EntityTypes, Maybe} from "../../../generated/graphql";
import {useMutation} from "@apollo/client";
import {SINGLE_UPLOAD_MUTATION} from "../../../graphql/mutations";

export function useUploadFile(archivosData: Maybe<Archivo[]> | undefined) {
    const [uploadFile, uploadFileResponse] = useMutation(SINGLE_UPLOAD_MUTATION);

    const [documents, setDocuments] = useState<DocumentNames[]>([]);

    useEffect(() => {
        if (archivosData) {
            setDocuments(archivosData.map((archivo) => (archivo.fileName as DocumentNames)))
        }
    }, [archivosData])

    const onInputFileChange = async (event: React.ChangeEvent<HTMLInputElement>, entityId: string, entityType: EntityTypes) => {
        const {
            target: {
                validity,
                files,
                name
            },
        } = event;

        if (validity.valid && files) {
            const file = files[0];

            await uploadFile({
                variables: {
                    input: {
                        entityId,
                        entityType,
                        documentType: name,
                        fileUpload: file
                    }
                }
            })
        }
    }

    return {
        documents,
        onInputFileChange,
        uploadFileResponse
    }
}
