import React from 'react';
import Typography from "@mui/material/Typography";
import {gql, useQuery} from "@apollo/client";

const RANDOM_QUOTE_QUERY = gql`
    query RandomQuote {
        randomQuote {
            quote
            author
        }
    }
`

const Main = () => {
    const {data, error} = useQuery(RANDOM_QUOTE_QUERY);

    if (error) {
        return <div>Error</div>
    }

    return (
        <>
            <Typography>{data?.randomQuote.quote}</Typography>
            <Typography>{data?.randomQuote.author}</Typography>
        </>
    )
}

export default Main
