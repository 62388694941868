import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Archivo = {
  __typename?: 'Archivo';
  fileName: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
};

export type ArchivosInput = {
  documentType: DocumentNames;
  entityId: Scalars['ID'];
  entityType: EntityTypes;
  fileUpload: Scalars['Upload'];
};

export type AsignarServicioInput = {
  descripcion: Scalars['String'];
  empresaId: Scalars['ID'];
};

export type AsignarServiciosSucursalInput = {
  id: Scalars['ID'];
  serviciosIds: Array<Scalars['ID']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  expiresAt: Scalars['Int'];
  token: Scalars['String'];
  user: User;
};

export type Bitacora = {
  __typename?: 'Bitacora';
  archivos?: Maybe<Array<Archivo>>;
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaRegistro: Scalars['DateTime'];
  id: Scalars['ID'];
  personaReporte: Scalars['String'];
  sucursal: Sucursal;
  sucursalId: Scalars['ID'];
  tipoBitacora: TipoBitacora;
};

export enum CategoriasIncidente {
  Alto = 'ALTO',
  Bajo = 'BAJO',
  Medio = 'MEDIO'
}

export type CreateBitacoraInput = {
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaRegistro: Scalars['DateTime'];
  personaReporte: Scalars['String'];
  sucursalId: Scalars['ID'];
  tipoBitacoraId: Scalars['ID'];
};

export type CreateEmpresaInput = {
  nombre: Scalars['String'];
  rfc: Scalars['String'];
};

export type CreateFacturaInput = {
  desde: Scalars['DateTime'];
  folio: Scalars['String'];
  hasta: Scalars['DateTime'];
  monto: Scalars['Float'];
  servicioId: Scalars['ID'];
  sucursalId: Scalars['ID'];
};

export type CreateIncidenteInput = {
  accion?: InputMaybe<Scalars['String']>;
  categoria: CategoriasIncidente;
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaCompromiso?: InputMaybe<Scalars['DateTime']>;
  fechaRegistro: Scalars['DateTime'];
  incidencia: Scalars['String'];
  sucursalId: Scalars['ID'];
};

export type CreateSucursalInput = {
  empresaId: Scalars['ID'];
  nombre: Scalars['String'];
  ubicacion: Scalars['String'];
};

export type CreateUpdateServicioExtraInput = {
  clienteID: Scalars['ID'];
  detalle: Scalars['String'];
  estatus: EstatusServicioExtra;
  fecha: Scalars['DateTime'];
  folioCobro?: InputMaybe<Scalars['String']>;
  horario: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  proveedorID: Scalars['ID'];
  recurrente?: InputMaybe<Scalars['Boolean']>;
};

/** Name of document to be saved */
export enum DocumentNames {
  Evidencia = 'EVIDENCIA',
  FacturaPdf = 'FACTURA_PDF',
  FacturaXml = 'FACTURA_XML',
  OrdenDeCompra = 'ORDEN_DE_COMPRA',
  Protocolo = 'PROTOCOLO',
  RegistroBitacora = 'REGISTRO_BITACORA',
  SoporteDeCliente = 'SOPORTE_DE_CLIENTE'
}

export type Empresa = {
  __typename?: 'Empresa';
  activa: Scalars['Boolean'];
  id: Scalars['ID'];
  nombre: Scalars['String'];
  rfc: Scalars['String'];
  tipo: Scalars['String'];
};

/** EntityType to relate upload */
export enum EntityTypes {
  Bitacora = 'BITACORA',
  Factura = 'FACTURA',
  Incidente = 'INCIDENTE'
}

/** Estatus por los que un Servicio de Catering Vario debe pasar */
export enum EstatusServicioExtra {
  Autorizado = 'AUTORIZADO',
  Cotizado = 'COTIZADO',
  Nuevo = 'NUEVO',
  Realizado = 'REALIZADO'
}

export type Factura = {
  __typename?: 'Factura';
  archivos?: Maybe<Array<Archivo>>;
  desde: Scalars['DateTime'];
  folio: Scalars['String'];
  hasta: Scalars['DateTime'];
  id: Scalars['ID'];
  monto: Scalars['Float'];
  servicio: Servicio;
  sucursal: Sucursal;
  sucursalId: Scalars['ID'];
};

export type FindBitacorasInput = {
  desde: Scalars['DateTime'];
  empresaId: Scalars['ID'];
  hasta: Scalars['DateTime'];
  sucursalId?: InputMaybe<Scalars['ID']>;
};

export type FindIncidentesInput = {
  abiertos?: Scalars['Boolean'];
  cerrados?: Scalars['Boolean'];
  desde: Scalars['DateTime'];
  empresasIds: Array<Scalars['ID']>;
  hasta: Scalars['DateTime'];
};

export type Incidente = {
  __typename?: 'Incidente';
  abierto: Scalars['Boolean'];
  accion?: Maybe<Scalars['String']>;
  archivos?: Maybe<Array<Archivo>>;
  categoria: CategoriasIncidente;
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaCompromiso?: Maybe<Scalars['DateTime']>;
  fechaRegistro: Scalars['DateTime'];
  id: Scalars['ID'];
  incidencia: Scalars['String'];
  sucursal: Sucursal;
  sucursalId: Scalars['ID'];
};

export type IncidentesQuery = {
  empresasIds: Array<Scalars['ID']>;
  incluirCerrados?: Scalars['Boolean'];
};

export type InspirationalQuote = {
  __typename?: 'InspirationalQuote';
  author?: Maybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type LoadFacturasInput = {
  desde: Scalars['DateTime'];
  empresaId: Scalars['ID'];
  hasta: Scalars['DateTime'];
  sucursalId?: InputMaybe<Scalars['ID']>;
};

export type ModificarServicioInput = {
  descripcion: Scalars['String'];
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activarDesactivarEmpresa: Empresa;
  activarDesactivarSucursal: Sucursal;
  actualizarBitacora: Bitacora;
  agregarSucursal: Sucursal;
  altaCliente: Empresa;
  altaServicio: Servicio;
  asignarServicios: Sucursal;
  bajaCliente: Empresa;
  crearEmpresa: Empresa;
  crearFactura: Factura;
  createIncidente: Incidente;
  createTipoBitacora: TipoBitacora;
  login: AuthPayload;
  modificarServicio: Servicio;
  modificarSucursal: Sucursal;
  registrarBitacora: Bitacora;
  registrarServicioExtra: ServicioExtra;
  signup: AuthPayload;
  toggleIncidenteEstatus: Scalars['Boolean'];
  updateEmpresa: Empresa;
  updateFactura: Factura;
  updateIncidente: Incidente;
  updateTipoBitacora: TipoBitacora;
  uploadFile: Scalars['Boolean'];
};


export type MutationActivarDesactivarEmpresaArgs = {
  id: Scalars['String'];
};


export type MutationActivarDesactivarSucursalArgs = {
  sucursalId: Scalars['String'];
};


export type MutationActualizarBitacoraArgs = {
  input: UpdateBitacoraInput;
};


export type MutationAgregarSucursalArgs = {
  input: CreateSucursalInput;
};


export type MutationAltaClienteArgs = {
  clienteId: Scalars['String'];
  empresaId: Scalars['String'];
};


export type MutationAltaServicioArgs = {
  input: AsignarServicioInput;
};


export type MutationAsignarServiciosArgs = {
  input: AsignarServiciosSucursalInput;
};


export type MutationBajaClienteArgs = {
  clienteId: Scalars['String'];
  empresaId: Scalars['String'];
};


export type MutationCrearEmpresaArgs = {
  input: CreateEmpresaInput;
};


export type MutationCrearFacturaArgs = {
  input: CreateFacturaInput;
};


export type MutationCreateIncidenteArgs = {
  input: CreateIncidenteInput;
};


export type MutationCreateTipoBitacoraArgs = {
  input: TipoBitacoraInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationModificarServicioArgs = {
  input: ModificarServicioInput;
};


export type MutationModificarSucursalArgs = {
  input: UpdateSucursalInput;
};


export type MutationRegistrarBitacoraArgs = {
  input: CreateBitacoraInput;
};


export type MutationRegistrarServicioExtraArgs = {
  Input: CreateUpdateServicioExtraInput;
};


export type MutationSignupArgs = {
  input: SingUpInput;
};


export type MutationToggleIncidenteEstatusArgs = {
  incidenteId: Scalars['String'];
};


export type MutationUpdateEmpresaArgs = {
  input: UpdateEmpresaInput;
};


export type MutationUpdateFacturaArgs = {
  input: UpdateFacturaInput;
};


export type MutationUpdateIncidenteArgs = {
  input: UpdateIncidenteInput;
};


export type MutationUpdateTipoBitacoraArgs = {
  input: TipoBitacoraInput;
};


export type MutationUploadFileArgs = {
  input: ArchivosInput;
};

export type Query = {
  __typename?: 'Query';
  allEmpresas: Array<Empresa>;
  cargarServiciosExtras: Array<ServicioExtra>;
  clientes: Array<Empresa>;
  empresa: Empresa;
  facturaById: Factura;
  facturasByEmpresaId: Array<Factura>;
  facturasBySucursalId: Array<Factura>;
  findBitacoras: Array<Bitacora>;
  findFacturas: Array<Factura>;
  findIncidentes: Array<Incidente>;
  findTiposBitacora: Array<TipoBitacora>;
  loadBitacoraById: Bitacora;
  loadBitacorasByEmpresaId: Array<Bitacora>;
  loadIncidenteById: Incidente;
  loadIncidentesByEmpresaId: Array<Incidente>;
  loadIncidentesByEmpresasIds: Array<Incidente>;
  loadIncidentesBySucursalId: Array<Incidente>;
  loadSucursalById: Sucursal;
  otrasEmpresas: Array<Empresa>;
  randomQuote: InspirationalQuote;
  serviciosEmpresa: Array<Servicio>;
  sucursales: Array<Sucursal>;
};


export type QueryCargarServiciosExtrasArgs = {
  proveedorID: Scalars['String'];
};


export type QueryClientesArgs = {
  empresaId: Scalars['String'];
};


export type QueryEmpresaArgs = {
  empresaId: Scalars['String'];
};


export type QueryFacturaByIdArgs = {
  facturaId: Scalars['String'];
};


export type QueryFacturasByEmpresaIdArgs = {
  empresaId: Scalars['String'];
};


export type QueryFacturasBySucursalIdArgs = {
  sucursalId: Scalars['String'];
};


export type QueryFindBitacorasArgs = {
  input: FindBitacorasInput;
};


export type QueryFindFacturasArgs = {
  input: LoadFacturasInput;
};


export type QueryFindIncidentesArgs = {
  input: FindIncidentesInput;
};


export type QueryLoadBitacoraByIdArgs = {
  bitacoraId: Scalars['String'];
};


export type QueryLoadBitacorasByEmpresaIdArgs = {
  empresaId: Scalars['String'];
};


export type QueryLoadIncidenteByIdArgs = {
  incidenteId: Scalars['String'];
};


export type QueryLoadIncidentesByEmpresaIdArgs = {
  empresaId: Scalars['String'];
};


export type QueryLoadIncidentesByEmpresasIdsArgs = {
  input: IncidentesQuery;
};


export type QueryLoadIncidentesBySucursalIdArgs = {
  sucursalId: Scalars['String'];
};


export type QueryLoadSucursalByIdArgs = {
  sucursalId: Scalars['String'];
};


export type QueryOtrasEmpresasArgs = {
  empresaId: Scalars['String'];
};


export type QueryServiciosEmpresaArgs = {
  empresaId: Scalars['String'];
};


export type QuerySucursalesArgs = {
  empresaId: Scalars['String'];
};

export type Servicio = {
  __typename?: 'Servicio';
  descripcion: Scalars['String'];
  id: Scalars['ID'];
};

export type ServicioExtra = {
  __typename?: 'ServicioExtra';
  clienteID: Scalars['ID'];
  detalle: Scalars['String'];
  estatus: EstatusServicioExtra;
  fecha: Scalars['DateTime'];
  folioCobro: Scalars['String'];
  horario: Scalars['String'];
  id: Scalars['ID'];
  proveedorID: Scalars['ID'];
  recurrente: Scalars['Boolean'];
};

export type SingUpInput = {
  email: Scalars['String'];
  empresaID: Scalars['String'];
  password: Scalars['String'];
};

export type Sucursal = {
  __typename?: 'Sucursal';
  activa: Scalars['Boolean'];
  id: Scalars['ID'];
  nombre: Scalars['String'];
  servicios?: Maybe<Array<Servicio>>;
  ubicacion: Scalars['String'];
};

export type TipoBitacora = {
  __typename?: 'TipoBitacora';
  descripcion: Scalars['String'];
  id: Scalars['ID'];
};

export type TipoBitacoraInput = {
  descripcion: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export type UpdateBitacoraInput = {
  bitacoraId: Scalars['ID'];
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaRegistro: Scalars['DateTime'];
  personaReporte: Scalars['String'];
  sucursalId: Scalars['ID'];
  tipoBitacoraId: Scalars['ID'];
};

export type UpdateEmpresaInput = {
  id: Scalars['ID'];
  nombre: Scalars['String'];
  rfc: Scalars['String'];
};

export type UpdateFacturaInput = {
  desde: Scalars['DateTime'];
  facturaId: Scalars['ID'];
  folio: Scalars['String'];
  hasta: Scalars['DateTime'];
  monto: Scalars['Float'];
  servicioId: Scalars['ID'];
  sucursalId: Scalars['ID'];
};

export type UpdateIncidenteInput = {
  accion: Scalars['String'];
  categoria: CategoriasIncidente;
  comentarios: Scalars['String'];
  empresaId: Scalars['ID'];
  fechaCompromiso: Scalars['DateTime'];
  fechaRegistro: Scalars['DateTime'];
  incidencia: Scalars['String'];
  incidenteId: Scalars['ID'];
  sucursalId: Scalars['ID'];
};

export type UpdateSucursalInput = {
  id: Scalars['ID'];
  nombre: Scalars['String'];
  ubicacion: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['String'];
  email: Scalars['String'];
  empresaID: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type SingleUploadMutationVariables = Exact<{
  input: ArchivosInput;
}>;


export type SingleUploadMutation = { __typename?: 'Mutation', uploadFile: boolean };

export type BitacoraFieldsFragment = { __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } };

export type RegistrarBitacoraMutationVariables = Exact<{
  input: CreateBitacoraInput;
}>;


export type RegistrarBitacoraMutation = { __typename?: 'Mutation', registrarBitacora: { __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } } };

export type ActualizarBitacoraMutationVariables = Exact<{
  input: UpdateBitacoraInput;
}>;


export type ActualizarBitacoraMutation = { __typename?: 'Mutation', actualizarBitacora: { __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } } };

export type LoadBitacorasByEmpresaIdQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type LoadBitacorasByEmpresaIdQuery = { __typename?: 'Query', loadBitacorasByEmpresaId: Array<{ __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } }> };

export type LoadBitacoraByIdQueryVariables = Exact<{
  bitacoraId: Scalars['String'];
}>;


export type LoadBitacoraByIdQuery = { __typename?: 'Query', loadBitacoraById: { __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } } };

export type FindBitacorasQueryVariables = Exact<{
  input: FindBitacorasInput;
}>;


export type FindBitacorasQuery = { __typename?: 'Query', findBitacoras: Array<{ __typename?: 'Bitacora', id: string, fechaRegistro: any, personaReporte: string, comentarios: string, sucursalId: string, empresaId: string, tipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } }> };

export type ClientesQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type ClientesQuery = { __typename?: 'Query', clientes: Array<{ __typename?: 'Empresa', id: string, nombre: string, activa: boolean, rfc: string, tipo: string }>, otrasEmpresas: Array<{ __typename?: 'Empresa', id: string, nombre: string, activa: boolean, rfc: string, tipo: string }> };

export type FacturaFieldsFragment = { __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null };

export type CrearFacturaMutationVariables = Exact<{
  input: CreateFacturaInput;
}>;


export type CrearFacturaMutation = { __typename?: 'Mutation', crearFactura: { __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type UpdateFacturaMutationVariables = Exact<{
  input: UpdateFacturaInput;
}>;


export type UpdateFacturaMutation = { __typename?: 'Mutation', updateFactura: { __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type FacturaByIdQueryVariables = Exact<{
  facturaId: Scalars['String'];
}>;


export type FacturaByIdQuery = { __typename?: 'Query', facturaById: { __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type FacturasBySucursalIdQueryVariables = Exact<{
  sucursalId: Scalars['String'];
}>;


export type FacturasBySucursalIdQuery = { __typename?: 'Query', facturasBySucursalId: Array<{ __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type FacturasByEmpresaIdQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type FacturasByEmpresaIdQuery = { __typename?: 'Query', facturasByEmpresaId: Array<{ __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type FindFacturasQueryVariables = Exact<{
  input: LoadFacturasInput;
}>;


export type FindFacturasQuery = { __typename?: 'Query', findFacturas: Array<{ __typename?: 'Factura', id: string, desde: any, hasta: any, folio: string, monto: number, sucursalId: string, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, servicio: { __typename?: 'Servicio', id: string, descripcion: string }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type IncidenteFieldsFragment = { __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null };

export type CreateIncidenteMutationVariables = Exact<{
  input: CreateIncidenteInput;
}>;


export type CreateIncidenteMutation = { __typename?: 'Mutation', createIncidente: { __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type UpdateIncidenteMutationVariables = Exact<{
  input: UpdateIncidenteInput;
}>;


export type UpdateIncidenteMutation = { __typename?: 'Mutation', updateIncidente: { __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type LoadIncidentesByEmpresaIdQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type LoadIncidentesByEmpresaIdQuery = { __typename?: 'Query', loadIncidentesByEmpresaId: Array<{ __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type LoadIncidenteByIdQueryVariables = Exact<{
  incidenteId: Scalars['String'];
}>;


export type LoadIncidenteByIdQuery = { __typename?: 'Query', loadIncidenteById: { __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null } };

export type LoadIncidentesByEmpresasIdsQueryVariables = Exact<{
  input: IncidentesQuery;
}>;


export type LoadIncidentesByEmpresasIdsQuery = { __typename?: 'Query', loadIncidentesByEmpresasIds: Array<{ __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type FindIncidentesQueryVariables = Exact<{
  input: FindIncidentesInput;
}>;


export type FindIncidentesQuery = { __typename?: 'Query', findIncidentes: Array<{ __typename?: 'Incidente', id: string, fechaRegistro: any, fechaCompromiso?: any | null, incidencia: string, accion?: string | null, comentarios: string, abierto: boolean, empresaId: string, sucursalId: string, categoria: CategoriasIncidente, sucursal: { __typename?: 'Sucursal', id: string, nombre: string, activa: boolean, ubicacion: string, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }, archivos?: Array<{ __typename?: 'Archivo', id: string, fileName: string, key: string }> | null }> };

export type ToggleIncidenteEstatusMutationVariables = Exact<{
  incidenteId: Scalars['String'];
}>;


export type ToggleIncidenteEstatusMutation = { __typename?: 'Mutation', toggleIncidenteEstatus: boolean };

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string, expiresAt: number, userInfo: { __typename?: 'User', id: string, email: string, empresaID: string, roles: Array<string> } } };

export type ServiciosEmpresaQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type ServiciosEmpresaQuery = { __typename?: 'Query', serviciosEmpresa: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> };

export type LoadSucursalesQueryVariables = Exact<{
  empresaId: Scalars['String'];
}>;


export type LoadSucursalesQuery = { __typename?: 'Query', sucursales: Array<{ __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null }> };

export type LoadSucursalByIdQueryVariables = Exact<{
  sucursalId: Scalars['String'];
}>;


export type LoadSucursalByIdQuery = { __typename?: 'Query', loadSucursalById: { __typename?: 'Sucursal', id: string, nombre: string, ubicacion: string, activa: boolean, servicios?: Array<{ __typename?: 'Servicio', id: string, descripcion: string }> | null } };

export type CreateTipoBitacoraMutationVariables = Exact<{
  input: TipoBitacoraInput;
}>;


export type CreateTipoBitacoraMutation = { __typename?: 'Mutation', createTipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string } };

export type UpdateTipoBitacoraMutationVariables = Exact<{
  input: TipoBitacoraInput;
}>;


export type UpdateTipoBitacoraMutation = { __typename?: 'Mutation', updateTipoBitacora: { __typename?: 'TipoBitacora', id: string, descripcion: string } };

export type FindTiposBitacoraQueryVariables = Exact<{ [key: string]: never; }>;


export type FindTiposBitacoraQuery = { __typename?: 'Query', findTiposBitacora: Array<{ __typename?: 'TipoBitacora', id: string, descripcion: string }> };

export const BitacoraFieldsFragmentDoc = gql`
    fragment BitacoraFields on Bitacora {
  id
  fechaRegistro
  personaReporte
  comentarios
  sucursalId
  empresaId
  tipoBitacora {
    id
    descripcion
  }
  archivos {
    id
    fileName
    key
  }
  sucursal {
    id
    nombre
    ubicacion
    activa
    servicios {
      id
      descripcion
    }
  }
}
    `;
export const FacturaFieldsFragmentDoc = gql`
    fragment FacturaFields on Factura {
  id
  desde
  hasta
  folio
  monto
  sucursalId
  sucursal {
    id
    nombre
    activa
    ubicacion
    servicios {
      id
      descripcion
    }
  }
  servicio {
    id
    descripcion
  }
  archivos {
    id
    fileName
    key
  }
}
    `;
export const IncidenteFieldsFragmentDoc = gql`
    fragment IncidenteFields on Incidente {
  id
  fechaRegistro
  fechaCompromiso
  incidencia
  accion
  comentarios
  abierto
  empresaId
  sucursalId
  categoria
  sucursal {
    id
    nombre
    activa
    ubicacion
    servicios {
      id
      descripcion
    }
  }
  archivos {
    id
    fileName
    key
  }
}
    `;
export const SingleUploadDocument = gql`
    mutation singleUpload($input: ArchivosInput!) {
  uploadFile(input: $input)
}
    `;
export type SingleUploadMutationFn = Apollo.MutationFunction<SingleUploadMutation, SingleUploadMutationVariables>;

/**
 * __useSingleUploadMutation__
 *
 * To run a mutation, you first call `useSingleUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUploadMutation, { data, loading, error }] = useSingleUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSingleUploadMutation(baseOptions?: Apollo.MutationHookOptions<SingleUploadMutation, SingleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingleUploadMutation, SingleUploadMutationVariables>(SingleUploadDocument, options);
      }
export type SingleUploadMutationHookResult = ReturnType<typeof useSingleUploadMutation>;
export type SingleUploadMutationResult = Apollo.MutationResult<SingleUploadMutation>;
export type SingleUploadMutationOptions = Apollo.BaseMutationOptions<SingleUploadMutation, SingleUploadMutationVariables>;
export const RegistrarBitacoraDocument = gql`
    mutation RegistrarBitacora($input: CreateBitacoraInput!) {
  registrarBitacora(input: $input) {
    ...BitacoraFields
  }
}
    ${BitacoraFieldsFragmentDoc}`;
export type RegistrarBitacoraMutationFn = Apollo.MutationFunction<RegistrarBitacoraMutation, RegistrarBitacoraMutationVariables>;

/**
 * __useRegistrarBitacoraMutation__
 *
 * To run a mutation, you first call `useRegistrarBitacoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrarBitacoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrarBitacoraMutation, { data, loading, error }] = useRegistrarBitacoraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrarBitacoraMutation(baseOptions?: Apollo.MutationHookOptions<RegistrarBitacoraMutation, RegistrarBitacoraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegistrarBitacoraMutation, RegistrarBitacoraMutationVariables>(RegistrarBitacoraDocument, options);
      }
export type RegistrarBitacoraMutationHookResult = ReturnType<typeof useRegistrarBitacoraMutation>;
export type RegistrarBitacoraMutationResult = Apollo.MutationResult<RegistrarBitacoraMutation>;
export type RegistrarBitacoraMutationOptions = Apollo.BaseMutationOptions<RegistrarBitacoraMutation, RegistrarBitacoraMutationVariables>;
export const ActualizarBitacoraDocument = gql`
    mutation ActualizarBitacora($input: UpdateBitacoraInput!) {
  actualizarBitacora(input: $input) {
    ...BitacoraFields
  }
}
    ${BitacoraFieldsFragmentDoc}`;
export type ActualizarBitacoraMutationFn = Apollo.MutationFunction<ActualizarBitacoraMutation, ActualizarBitacoraMutationVariables>;

/**
 * __useActualizarBitacoraMutation__
 *
 * To run a mutation, you first call `useActualizarBitacoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActualizarBitacoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actualizarBitacoraMutation, { data, loading, error }] = useActualizarBitacoraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActualizarBitacoraMutation(baseOptions?: Apollo.MutationHookOptions<ActualizarBitacoraMutation, ActualizarBitacoraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActualizarBitacoraMutation, ActualizarBitacoraMutationVariables>(ActualizarBitacoraDocument, options);
      }
export type ActualizarBitacoraMutationHookResult = ReturnType<typeof useActualizarBitacoraMutation>;
export type ActualizarBitacoraMutationResult = Apollo.MutationResult<ActualizarBitacoraMutation>;
export type ActualizarBitacoraMutationOptions = Apollo.BaseMutationOptions<ActualizarBitacoraMutation, ActualizarBitacoraMutationVariables>;
export const LoadBitacorasByEmpresaIdDocument = gql`
    query LoadBitacorasByEmpresaId($empresaId: String!) {
  loadBitacorasByEmpresaId(empresaId: $empresaId) {
    ...BitacoraFields
  }
}
    ${BitacoraFieldsFragmentDoc}`;

/**
 * __useLoadBitacorasByEmpresaIdQuery__
 *
 * To run a query within a React component, call `useLoadBitacorasByEmpresaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBitacorasByEmpresaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBitacorasByEmpresaIdQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useLoadBitacorasByEmpresaIdQuery(baseOptions: Apollo.QueryHookOptions<LoadBitacorasByEmpresaIdQuery, LoadBitacorasByEmpresaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadBitacorasByEmpresaIdQuery, LoadBitacorasByEmpresaIdQueryVariables>(LoadBitacorasByEmpresaIdDocument, options);
      }
export function useLoadBitacorasByEmpresaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadBitacorasByEmpresaIdQuery, LoadBitacorasByEmpresaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadBitacorasByEmpresaIdQuery, LoadBitacorasByEmpresaIdQueryVariables>(LoadBitacorasByEmpresaIdDocument, options);
        }
export type LoadBitacorasByEmpresaIdQueryHookResult = ReturnType<typeof useLoadBitacorasByEmpresaIdQuery>;
export type LoadBitacorasByEmpresaIdLazyQueryHookResult = ReturnType<typeof useLoadBitacorasByEmpresaIdLazyQuery>;
export type LoadBitacorasByEmpresaIdQueryResult = Apollo.QueryResult<LoadBitacorasByEmpresaIdQuery, LoadBitacorasByEmpresaIdQueryVariables>;
export const LoadBitacoraByIdDocument = gql`
    query LoadBitacoraById($bitacoraId: String!) {
  loadBitacoraById(bitacoraId: $bitacoraId) {
    ...BitacoraFields
  }
}
    ${BitacoraFieldsFragmentDoc}`;

/**
 * __useLoadBitacoraByIdQuery__
 *
 * To run a query within a React component, call `useLoadBitacoraByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBitacoraByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBitacoraByIdQuery({
 *   variables: {
 *      bitacoraId: // value for 'bitacoraId'
 *   },
 * });
 */
export function useLoadBitacoraByIdQuery(baseOptions: Apollo.QueryHookOptions<LoadBitacoraByIdQuery, LoadBitacoraByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadBitacoraByIdQuery, LoadBitacoraByIdQueryVariables>(LoadBitacoraByIdDocument, options);
      }
export function useLoadBitacoraByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadBitacoraByIdQuery, LoadBitacoraByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadBitacoraByIdQuery, LoadBitacoraByIdQueryVariables>(LoadBitacoraByIdDocument, options);
        }
export type LoadBitacoraByIdQueryHookResult = ReturnType<typeof useLoadBitacoraByIdQuery>;
export type LoadBitacoraByIdLazyQueryHookResult = ReturnType<typeof useLoadBitacoraByIdLazyQuery>;
export type LoadBitacoraByIdQueryResult = Apollo.QueryResult<LoadBitacoraByIdQuery, LoadBitacoraByIdQueryVariables>;
export const FindBitacorasDocument = gql`
    query FindBitacoras($input: FindBitacorasInput!) {
  findBitacoras(input: $input) {
    ...BitacoraFields
  }
}
    ${BitacoraFieldsFragmentDoc}`;

/**
 * __useFindBitacorasQuery__
 *
 * To run a query within a React component, call `useFindBitacorasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBitacorasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBitacorasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindBitacorasQuery(baseOptions: Apollo.QueryHookOptions<FindBitacorasQuery, FindBitacorasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindBitacorasQuery, FindBitacorasQueryVariables>(FindBitacorasDocument, options);
      }
export function useFindBitacorasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBitacorasQuery, FindBitacorasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindBitacorasQuery, FindBitacorasQueryVariables>(FindBitacorasDocument, options);
        }
export type FindBitacorasQueryHookResult = ReturnType<typeof useFindBitacorasQuery>;
export type FindBitacorasLazyQueryHookResult = ReturnType<typeof useFindBitacorasLazyQuery>;
export type FindBitacorasQueryResult = Apollo.QueryResult<FindBitacorasQuery, FindBitacorasQueryVariables>;
export const ClientesDocument = gql`
    query Clientes($empresaId: String!) {
  clientes(empresaId: $empresaId) {
    id
    nombre
    activa
    rfc
    tipo
  }
  otrasEmpresas(empresaId: $empresaId) {
    id
    nombre
    activa
    rfc
    tipo
  }
}
    `;

/**
 * __useClientesQuery__
 *
 * To run a query within a React component, call `useClientesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientesQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useClientesQuery(baseOptions: Apollo.QueryHookOptions<ClientesQuery, ClientesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientesQuery, ClientesQueryVariables>(ClientesDocument, options);
      }
export function useClientesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientesQuery, ClientesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientesQuery, ClientesQueryVariables>(ClientesDocument, options);
        }
export type ClientesQueryHookResult = ReturnType<typeof useClientesQuery>;
export type ClientesLazyQueryHookResult = ReturnType<typeof useClientesLazyQuery>;
export type ClientesQueryResult = Apollo.QueryResult<ClientesQuery, ClientesQueryVariables>;
export const CrearFacturaDocument = gql`
    mutation CrearFactura($input: CreateFacturaInput!) {
  crearFactura(input: $input) {
    ...FacturaFields
  }
}
    ${FacturaFieldsFragmentDoc}`;
export type CrearFacturaMutationFn = Apollo.MutationFunction<CrearFacturaMutation, CrearFacturaMutationVariables>;

/**
 * __useCrearFacturaMutation__
 *
 * To run a mutation, you first call `useCrearFacturaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrearFacturaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crearFacturaMutation, { data, loading, error }] = useCrearFacturaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCrearFacturaMutation(baseOptions?: Apollo.MutationHookOptions<CrearFacturaMutation, CrearFacturaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrearFacturaMutation, CrearFacturaMutationVariables>(CrearFacturaDocument, options);
      }
export type CrearFacturaMutationHookResult = ReturnType<typeof useCrearFacturaMutation>;
export type CrearFacturaMutationResult = Apollo.MutationResult<CrearFacturaMutation>;
export type CrearFacturaMutationOptions = Apollo.BaseMutationOptions<CrearFacturaMutation, CrearFacturaMutationVariables>;
export const UpdateFacturaDocument = gql`
    mutation UpdateFactura($input: UpdateFacturaInput!) {
  updateFactura(input: $input) {
    ...FacturaFields
  }
}
    ${FacturaFieldsFragmentDoc}`;
export type UpdateFacturaMutationFn = Apollo.MutationFunction<UpdateFacturaMutation, UpdateFacturaMutationVariables>;

/**
 * __useUpdateFacturaMutation__
 *
 * To run a mutation, you first call `useUpdateFacturaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacturaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacturaMutation, { data, loading, error }] = useUpdateFacturaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacturaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacturaMutation, UpdateFacturaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacturaMutation, UpdateFacturaMutationVariables>(UpdateFacturaDocument, options);
      }
export type UpdateFacturaMutationHookResult = ReturnType<typeof useUpdateFacturaMutation>;
export type UpdateFacturaMutationResult = Apollo.MutationResult<UpdateFacturaMutation>;
export type UpdateFacturaMutationOptions = Apollo.BaseMutationOptions<UpdateFacturaMutation, UpdateFacturaMutationVariables>;
export const FacturaByIdDocument = gql`
    query FacturaById($facturaId: String!) {
  facturaById(facturaId: $facturaId) {
    id
    desde
    hasta
    folio
    monto
    sucursalId
    sucursal {
      id
      nombre
      activa
      ubicacion
      servicios {
        id
        descripcion
      }
    }
    servicio {
      id
      descripcion
    }
    archivos {
      id
      fileName
      key
    }
  }
}
    `;

/**
 * __useFacturaByIdQuery__
 *
 * To run a query within a React component, call `useFacturaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacturaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacturaByIdQuery({
 *   variables: {
 *      facturaId: // value for 'facturaId'
 *   },
 * });
 */
export function useFacturaByIdQuery(baseOptions: Apollo.QueryHookOptions<FacturaByIdQuery, FacturaByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacturaByIdQuery, FacturaByIdQueryVariables>(FacturaByIdDocument, options);
      }
export function useFacturaByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacturaByIdQuery, FacturaByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacturaByIdQuery, FacturaByIdQueryVariables>(FacturaByIdDocument, options);
        }
export type FacturaByIdQueryHookResult = ReturnType<typeof useFacturaByIdQuery>;
export type FacturaByIdLazyQueryHookResult = ReturnType<typeof useFacturaByIdLazyQuery>;
export type FacturaByIdQueryResult = Apollo.QueryResult<FacturaByIdQuery, FacturaByIdQueryVariables>;
export const FacturasBySucursalIdDocument = gql`
    query FacturasBySucursalId($sucursalId: String!) {
  facturasBySucursalId(sucursalId: $sucursalId) {
    ...FacturaFields
  }
}
    ${FacturaFieldsFragmentDoc}`;

/**
 * __useFacturasBySucursalIdQuery__
 *
 * To run a query within a React component, call `useFacturasBySucursalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacturasBySucursalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacturasBySucursalIdQuery({
 *   variables: {
 *      sucursalId: // value for 'sucursalId'
 *   },
 * });
 */
export function useFacturasBySucursalIdQuery(baseOptions: Apollo.QueryHookOptions<FacturasBySucursalIdQuery, FacturasBySucursalIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacturasBySucursalIdQuery, FacturasBySucursalIdQueryVariables>(FacturasBySucursalIdDocument, options);
      }
export function useFacturasBySucursalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacturasBySucursalIdQuery, FacturasBySucursalIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacturasBySucursalIdQuery, FacturasBySucursalIdQueryVariables>(FacturasBySucursalIdDocument, options);
        }
export type FacturasBySucursalIdQueryHookResult = ReturnType<typeof useFacturasBySucursalIdQuery>;
export type FacturasBySucursalIdLazyQueryHookResult = ReturnType<typeof useFacturasBySucursalIdLazyQuery>;
export type FacturasBySucursalIdQueryResult = Apollo.QueryResult<FacturasBySucursalIdQuery, FacturasBySucursalIdQueryVariables>;
export const FacturasByEmpresaIdDocument = gql`
    query FacturasByEmpresaId($empresaId: String!) {
  facturasByEmpresaId(empresaId: $empresaId) {
    ...FacturaFields
  }
}
    ${FacturaFieldsFragmentDoc}`;

/**
 * __useFacturasByEmpresaIdQuery__
 *
 * To run a query within a React component, call `useFacturasByEmpresaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacturasByEmpresaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacturasByEmpresaIdQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useFacturasByEmpresaIdQuery(baseOptions: Apollo.QueryHookOptions<FacturasByEmpresaIdQuery, FacturasByEmpresaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacturasByEmpresaIdQuery, FacturasByEmpresaIdQueryVariables>(FacturasByEmpresaIdDocument, options);
      }
export function useFacturasByEmpresaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacturasByEmpresaIdQuery, FacturasByEmpresaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacturasByEmpresaIdQuery, FacturasByEmpresaIdQueryVariables>(FacturasByEmpresaIdDocument, options);
        }
export type FacturasByEmpresaIdQueryHookResult = ReturnType<typeof useFacturasByEmpresaIdQuery>;
export type FacturasByEmpresaIdLazyQueryHookResult = ReturnType<typeof useFacturasByEmpresaIdLazyQuery>;
export type FacturasByEmpresaIdQueryResult = Apollo.QueryResult<FacturasByEmpresaIdQuery, FacturasByEmpresaIdQueryVariables>;
export const FindFacturasDocument = gql`
    query FindFacturas($input: LoadFacturasInput!) {
  findFacturas(input: $input) {
    ...FacturaFields
  }
}
    ${FacturaFieldsFragmentDoc}`;

/**
 * __useFindFacturasQuery__
 *
 * To run a query within a React component, call `useFindFacturasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFacturasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFacturasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindFacturasQuery(baseOptions: Apollo.QueryHookOptions<FindFacturasQuery, FindFacturasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFacturasQuery, FindFacturasQueryVariables>(FindFacturasDocument, options);
      }
export function useFindFacturasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFacturasQuery, FindFacturasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFacturasQuery, FindFacturasQueryVariables>(FindFacturasDocument, options);
        }
export type FindFacturasQueryHookResult = ReturnType<typeof useFindFacturasQuery>;
export type FindFacturasLazyQueryHookResult = ReturnType<typeof useFindFacturasLazyQuery>;
export type FindFacturasQueryResult = Apollo.QueryResult<FindFacturasQuery, FindFacturasQueryVariables>;
export const CreateIncidenteDocument = gql`
    mutation CreateIncidente($input: CreateIncidenteInput!) {
  createIncidente(input: $input) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;
export type CreateIncidenteMutationFn = Apollo.MutationFunction<CreateIncidenteMutation, CreateIncidenteMutationVariables>;

/**
 * __useCreateIncidenteMutation__
 *
 * To run a mutation, you first call `useCreateIncidenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidenteMutation, { data, loading, error }] = useCreateIncidenteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidenteMutation(baseOptions?: Apollo.MutationHookOptions<CreateIncidenteMutation, CreateIncidenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIncidenteMutation, CreateIncidenteMutationVariables>(CreateIncidenteDocument, options);
      }
export type CreateIncidenteMutationHookResult = ReturnType<typeof useCreateIncidenteMutation>;
export type CreateIncidenteMutationResult = Apollo.MutationResult<CreateIncidenteMutation>;
export type CreateIncidenteMutationOptions = Apollo.BaseMutationOptions<CreateIncidenteMutation, CreateIncidenteMutationVariables>;
export const UpdateIncidenteDocument = gql`
    mutation UpdateIncidente($input: UpdateIncidenteInput!) {
  updateIncidente(input: $input) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;
export type UpdateIncidenteMutationFn = Apollo.MutationFunction<UpdateIncidenteMutation, UpdateIncidenteMutationVariables>;

/**
 * __useUpdateIncidenteMutation__
 *
 * To run a mutation, you first call `useUpdateIncidenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidenteMutation, { data, loading, error }] = useUpdateIncidenteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIncidenteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncidenteMutation, UpdateIncidenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncidenteMutation, UpdateIncidenteMutationVariables>(UpdateIncidenteDocument, options);
      }
export type UpdateIncidenteMutationHookResult = ReturnType<typeof useUpdateIncidenteMutation>;
export type UpdateIncidenteMutationResult = Apollo.MutationResult<UpdateIncidenteMutation>;
export type UpdateIncidenteMutationOptions = Apollo.BaseMutationOptions<UpdateIncidenteMutation, UpdateIncidenteMutationVariables>;
export const LoadIncidentesByEmpresaIdDocument = gql`
    query LoadIncidentesByEmpresaId($empresaId: String!) {
  loadIncidentesByEmpresaId(empresaId: $empresaId) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;

/**
 * __useLoadIncidentesByEmpresaIdQuery__
 *
 * To run a query within a React component, call `useLoadIncidentesByEmpresaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadIncidentesByEmpresaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadIncidentesByEmpresaIdQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useLoadIncidentesByEmpresaIdQuery(baseOptions: Apollo.QueryHookOptions<LoadIncidentesByEmpresaIdQuery, LoadIncidentesByEmpresaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadIncidentesByEmpresaIdQuery, LoadIncidentesByEmpresaIdQueryVariables>(LoadIncidentesByEmpresaIdDocument, options);
      }
export function useLoadIncidentesByEmpresaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadIncidentesByEmpresaIdQuery, LoadIncidentesByEmpresaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadIncidentesByEmpresaIdQuery, LoadIncidentesByEmpresaIdQueryVariables>(LoadIncidentesByEmpresaIdDocument, options);
        }
export type LoadIncidentesByEmpresaIdQueryHookResult = ReturnType<typeof useLoadIncidentesByEmpresaIdQuery>;
export type LoadIncidentesByEmpresaIdLazyQueryHookResult = ReturnType<typeof useLoadIncidentesByEmpresaIdLazyQuery>;
export type LoadIncidentesByEmpresaIdQueryResult = Apollo.QueryResult<LoadIncidentesByEmpresaIdQuery, LoadIncidentesByEmpresaIdQueryVariables>;
export const LoadIncidenteByIdDocument = gql`
    query LoadIncidenteById($incidenteId: String!) {
  loadIncidenteById(incidenteId: $incidenteId) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;

/**
 * __useLoadIncidenteByIdQuery__
 *
 * To run a query within a React component, call `useLoadIncidenteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadIncidenteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadIncidenteByIdQuery({
 *   variables: {
 *      incidenteId: // value for 'incidenteId'
 *   },
 * });
 */
export function useLoadIncidenteByIdQuery(baseOptions: Apollo.QueryHookOptions<LoadIncidenteByIdQuery, LoadIncidenteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadIncidenteByIdQuery, LoadIncidenteByIdQueryVariables>(LoadIncidenteByIdDocument, options);
      }
export function useLoadIncidenteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadIncidenteByIdQuery, LoadIncidenteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadIncidenteByIdQuery, LoadIncidenteByIdQueryVariables>(LoadIncidenteByIdDocument, options);
        }
export type LoadIncidenteByIdQueryHookResult = ReturnType<typeof useLoadIncidenteByIdQuery>;
export type LoadIncidenteByIdLazyQueryHookResult = ReturnType<typeof useLoadIncidenteByIdLazyQuery>;
export type LoadIncidenteByIdQueryResult = Apollo.QueryResult<LoadIncidenteByIdQuery, LoadIncidenteByIdQueryVariables>;
export const LoadIncidentesByEmpresasIdsDocument = gql`
    query LoadIncidentesByEmpresasIds($input: IncidentesQuery!) {
  loadIncidentesByEmpresasIds(input: $input) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;

/**
 * __useLoadIncidentesByEmpresasIdsQuery__
 *
 * To run a query within a React component, call `useLoadIncidentesByEmpresasIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadIncidentesByEmpresasIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadIncidentesByEmpresasIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadIncidentesByEmpresasIdsQuery(baseOptions: Apollo.QueryHookOptions<LoadIncidentesByEmpresasIdsQuery, LoadIncidentesByEmpresasIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadIncidentesByEmpresasIdsQuery, LoadIncidentesByEmpresasIdsQueryVariables>(LoadIncidentesByEmpresasIdsDocument, options);
      }
export function useLoadIncidentesByEmpresasIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadIncidentesByEmpresasIdsQuery, LoadIncidentesByEmpresasIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadIncidentesByEmpresasIdsQuery, LoadIncidentesByEmpresasIdsQueryVariables>(LoadIncidentesByEmpresasIdsDocument, options);
        }
export type LoadIncidentesByEmpresasIdsQueryHookResult = ReturnType<typeof useLoadIncidentesByEmpresasIdsQuery>;
export type LoadIncidentesByEmpresasIdsLazyQueryHookResult = ReturnType<typeof useLoadIncidentesByEmpresasIdsLazyQuery>;
export type LoadIncidentesByEmpresasIdsQueryResult = Apollo.QueryResult<LoadIncidentesByEmpresasIdsQuery, LoadIncidentesByEmpresasIdsQueryVariables>;
export const FindIncidentesDocument = gql`
    query FindIncidentes($input: FindIncidentesInput!) {
  findIncidentes(input: $input) {
    ...IncidenteFields
  }
}
    ${IncidenteFieldsFragmentDoc}`;

/**
 * __useFindIncidentesQuery__
 *
 * To run a query within a React component, call `useFindIncidentesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIncidentesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIncidentesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindIncidentesQuery(baseOptions: Apollo.QueryHookOptions<FindIncidentesQuery, FindIncidentesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindIncidentesQuery, FindIncidentesQueryVariables>(FindIncidentesDocument, options);
      }
export function useFindIncidentesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindIncidentesQuery, FindIncidentesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindIncidentesQuery, FindIncidentesQueryVariables>(FindIncidentesDocument, options);
        }
export type FindIncidentesQueryHookResult = ReturnType<typeof useFindIncidentesQuery>;
export type FindIncidentesLazyQueryHookResult = ReturnType<typeof useFindIncidentesLazyQuery>;
export type FindIncidentesQueryResult = Apollo.QueryResult<FindIncidentesQuery, FindIncidentesQueryVariables>;
export const ToggleIncidenteEstatusDocument = gql`
    mutation ToggleIncidenteEstatus($incidenteId: String!) {
  toggleIncidenteEstatus(incidenteId: $incidenteId)
}
    `;
export type ToggleIncidenteEstatusMutationFn = Apollo.MutationFunction<ToggleIncidenteEstatusMutation, ToggleIncidenteEstatusMutationVariables>;

/**
 * __useToggleIncidenteEstatusMutation__
 *
 * To run a mutation, you first call `useToggleIncidenteEstatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIncidenteEstatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIncidenteEstatusMutation, { data, loading, error }] = useToggleIncidenteEstatusMutation({
 *   variables: {
 *      incidenteId: // value for 'incidenteId'
 *   },
 * });
 */
export function useToggleIncidenteEstatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIncidenteEstatusMutation, ToggleIncidenteEstatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIncidenteEstatusMutation, ToggleIncidenteEstatusMutationVariables>(ToggleIncidenteEstatusDocument, options);
      }
export type ToggleIncidenteEstatusMutationHookResult = ReturnType<typeof useToggleIncidenteEstatusMutation>;
export type ToggleIncidenteEstatusMutationResult = Apollo.MutationResult<ToggleIncidenteEstatusMutation>;
export type ToggleIncidenteEstatusMutationOptions = Apollo.BaseMutationOptions<ToggleIncidenteEstatusMutation, ToggleIncidenteEstatusMutationVariables>;
export const LoginDocument = gql`
    mutation Login($password: String!, $email: String!) {
  login(password: $password, email: $email) {
    userInfo: user {
      id
      email
      empresaID
      roles
    }
    token
    expiresAt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ServiciosEmpresaDocument = gql`
    query ServiciosEmpresa($empresaId: String!) {
  serviciosEmpresa(empresaId: $empresaId) {
    id
    descripcion
  }
}
    `;

/**
 * __useServiciosEmpresaQuery__
 *
 * To run a query within a React component, call `useServiciosEmpresaQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiciosEmpresaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiciosEmpresaQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useServiciosEmpresaQuery(baseOptions: Apollo.QueryHookOptions<ServiciosEmpresaQuery, ServiciosEmpresaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiciosEmpresaQuery, ServiciosEmpresaQueryVariables>(ServiciosEmpresaDocument, options);
      }
export function useServiciosEmpresaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiciosEmpresaQuery, ServiciosEmpresaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiciosEmpresaQuery, ServiciosEmpresaQueryVariables>(ServiciosEmpresaDocument, options);
        }
export type ServiciosEmpresaQueryHookResult = ReturnType<typeof useServiciosEmpresaQuery>;
export type ServiciosEmpresaLazyQueryHookResult = ReturnType<typeof useServiciosEmpresaLazyQuery>;
export type ServiciosEmpresaQueryResult = Apollo.QueryResult<ServiciosEmpresaQuery, ServiciosEmpresaQueryVariables>;
export const LoadSucursalesDocument = gql`
    query LoadSucursales($empresaId: String!) {
  sucursales(empresaId: $empresaId) {
    id
    nombre
    ubicacion
    activa
    servicios {
      id
      descripcion
    }
  }
}
    `;

/**
 * __useLoadSucursalesQuery__
 *
 * To run a query within a React component, call `useLoadSucursalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSucursalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSucursalesQuery({
 *   variables: {
 *      empresaId: // value for 'empresaId'
 *   },
 * });
 */
export function useLoadSucursalesQuery(baseOptions: Apollo.QueryHookOptions<LoadSucursalesQuery, LoadSucursalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSucursalesQuery, LoadSucursalesQueryVariables>(LoadSucursalesDocument, options);
      }
export function useLoadSucursalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSucursalesQuery, LoadSucursalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSucursalesQuery, LoadSucursalesQueryVariables>(LoadSucursalesDocument, options);
        }
export type LoadSucursalesQueryHookResult = ReturnType<typeof useLoadSucursalesQuery>;
export type LoadSucursalesLazyQueryHookResult = ReturnType<typeof useLoadSucursalesLazyQuery>;
export type LoadSucursalesQueryResult = Apollo.QueryResult<LoadSucursalesQuery, LoadSucursalesQueryVariables>;
export const LoadSucursalByIdDocument = gql`
    query LoadSucursalById($sucursalId: String!) {
  loadSucursalById(sucursalId: $sucursalId) {
    id
    nombre
    ubicacion
    activa
    servicios {
      id
      descripcion
    }
  }
}
    `;

/**
 * __useLoadSucursalByIdQuery__
 *
 * To run a query within a React component, call `useLoadSucursalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSucursalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSucursalByIdQuery({
 *   variables: {
 *      sucursalId: // value for 'sucursalId'
 *   },
 * });
 */
export function useLoadSucursalByIdQuery(baseOptions: Apollo.QueryHookOptions<LoadSucursalByIdQuery, LoadSucursalByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSucursalByIdQuery, LoadSucursalByIdQueryVariables>(LoadSucursalByIdDocument, options);
      }
export function useLoadSucursalByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSucursalByIdQuery, LoadSucursalByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSucursalByIdQuery, LoadSucursalByIdQueryVariables>(LoadSucursalByIdDocument, options);
        }
export type LoadSucursalByIdQueryHookResult = ReturnType<typeof useLoadSucursalByIdQuery>;
export type LoadSucursalByIdLazyQueryHookResult = ReturnType<typeof useLoadSucursalByIdLazyQuery>;
export type LoadSucursalByIdQueryResult = Apollo.QueryResult<LoadSucursalByIdQuery, LoadSucursalByIdQueryVariables>;
export const CreateTipoBitacoraDocument = gql`
    mutation CreateTipoBitacora($input: TipoBitacoraInput!) {
  createTipoBitacora(input: $input) {
    id
    descripcion
  }
}
    `;
export type CreateTipoBitacoraMutationFn = Apollo.MutationFunction<CreateTipoBitacoraMutation, CreateTipoBitacoraMutationVariables>;

/**
 * __useCreateTipoBitacoraMutation__
 *
 * To run a mutation, you first call `useCreateTipoBitacoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTipoBitacoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTipoBitacoraMutation, { data, loading, error }] = useCreateTipoBitacoraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTipoBitacoraMutation(baseOptions?: Apollo.MutationHookOptions<CreateTipoBitacoraMutation, CreateTipoBitacoraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTipoBitacoraMutation, CreateTipoBitacoraMutationVariables>(CreateTipoBitacoraDocument, options);
      }
export type CreateTipoBitacoraMutationHookResult = ReturnType<typeof useCreateTipoBitacoraMutation>;
export type CreateTipoBitacoraMutationResult = Apollo.MutationResult<CreateTipoBitacoraMutation>;
export type CreateTipoBitacoraMutationOptions = Apollo.BaseMutationOptions<CreateTipoBitacoraMutation, CreateTipoBitacoraMutationVariables>;
export const UpdateTipoBitacoraDocument = gql`
    mutation UpdateTipoBitacora($input: TipoBitacoraInput!) {
  updateTipoBitacora(input: $input) {
    id
    descripcion
  }
}
    `;
export type UpdateTipoBitacoraMutationFn = Apollo.MutationFunction<UpdateTipoBitacoraMutation, UpdateTipoBitacoraMutationVariables>;

/**
 * __useUpdateTipoBitacoraMutation__
 *
 * To run a mutation, you first call `useUpdateTipoBitacoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTipoBitacoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTipoBitacoraMutation, { data, loading, error }] = useUpdateTipoBitacoraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTipoBitacoraMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTipoBitacoraMutation, UpdateTipoBitacoraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTipoBitacoraMutation, UpdateTipoBitacoraMutationVariables>(UpdateTipoBitacoraDocument, options);
      }
export type UpdateTipoBitacoraMutationHookResult = ReturnType<typeof useUpdateTipoBitacoraMutation>;
export type UpdateTipoBitacoraMutationResult = Apollo.MutationResult<UpdateTipoBitacoraMutation>;
export type UpdateTipoBitacoraMutationOptions = Apollo.BaseMutationOptions<UpdateTipoBitacoraMutation, UpdateTipoBitacoraMutationVariables>;
export const FindTiposBitacoraDocument = gql`
    query FindTiposBitacora {
  findTiposBitacora {
    id
    descripcion
  }
}
    `;

/**
 * __useFindTiposBitacoraQuery__
 *
 * To run a query within a React component, call `useFindTiposBitacoraQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTiposBitacoraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTiposBitacoraQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindTiposBitacoraQuery(baseOptions?: Apollo.QueryHookOptions<FindTiposBitacoraQuery, FindTiposBitacoraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTiposBitacoraQuery, FindTiposBitacoraQueryVariables>(FindTiposBitacoraDocument, options);
      }
export function useFindTiposBitacoraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTiposBitacoraQuery, FindTiposBitacoraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTiposBitacoraQuery, FindTiposBitacoraQueryVariables>(FindTiposBitacoraDocument, options);
        }
export type FindTiposBitacoraQueryHookResult = ReturnType<typeof useFindTiposBitacoraQuery>;
export type FindTiposBitacoraLazyQueryHookResult = ReturnType<typeof useFindTiposBitacoraLazyQuery>;
export type FindTiposBitacoraQueryResult = Apollo.QueryResult<FindTiposBitacoraQuery, FindTiposBitacoraQueryVariables>;