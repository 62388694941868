import React from 'react';
import ReactDOM from 'react-dom';
import Authenticated from './Authenticated';
import reportWebVitals from './reportWebVitals';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    from
} from '@apollo/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import SignIn from "./components/Login";
import {onError} from "@apollo/client/link/error";
import {AuthProvider} from "./context/AuthContext";
import Main from "./components/Main";
import {setContext} from "@apollo/client/link/context";
import Clientes from "./components/Clientes/Clientes";
import Empresas from "./components/Empresas/Empresas";
import ModificarEmpresa from "./components/Empresas/Modificar";
import Sucursales from "./components/Clientes/Sucursales/Sucursales";
import Facturacion from "./components/Facturacion/Facturacion";
import { createUploadLink } from "apollo-upload-client";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import Incidentes from "./components/Incidentes/Incidentes";
import Bitacoras from "./components/Bitacoras/Bitacoras";
import TiposBitacora from "./components/TiposBitacora/TiposBitacora";

interface ThemeOptions {
    palette: {
        type: string;
        primary: {
            main: string;
        }
        secondary: {
            main: string;
        }
        error: {
            main: string;
        }
    }
}

export const themeOptions: ThemeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#d32f2f',
        },
        secondary: {
            main: '#3f51b5',
        },
        error: {
            main: '#ffeb3b',
        },
    },
};

const httpLink = createUploadLink({ uri: process.env.REACT_APP_GRAPH_URL })

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({message, locations, path}) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all'
    }
}

const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
        addTypename: false
    }),
    ...defaultOptions
})

const theme = createTheme(themeOptions)

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider>
                    <ApolloProvider client={client}>
                        <Routes>
                            <Route path="/" element={<Authenticated />}>
                                <Route path="" element={<Main />} />
                                <Route path="empresas" element={<Empresas />} />
                                <Route path="empresas/modificar/:id" element={<ModificarEmpresa />} />
                                <Route path="clientes" element={<Clientes />} />
                                <Route path="clientes/:id/sucursales" element={<Sucursales />} />
                                <Route path="facturacion" element={<Facturacion />} />
                                <Route path="incidentes" element={<Incidentes />} />
                                <Route path="bitacoras" element={<Bitacoras />} />
                                <Route path="tipos-bitacora" element={<TiposBitacora />} />
                            </Route>
                            <Route path='/login' element={<SignIn/>}/>
                        </Routes>
                    </ApolloProvider>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
