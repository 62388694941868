import React, {useEffect, useState} from 'react';
import {Box, Card} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {TipoBitacora, useCreateTipoBitacoraMutation, useUpdateTipoBitacoraMutation} from "../../../generated/graphql";
import Button from "@mui/material/Button";

interface IFormTipoBitacora {
    tipoBitacora?: TipoBitacora;
    onClose: () => void;
}

const FormTipoBitacora = ({tipoBitacora, onClose}: IFormTipoBitacora) => {

    const [tipoBitacoraData] = useState<TipoBitacora|undefined>(tipoBitacora);

    const [crearTipoBitacora, responseCrearTipoBitacora] = useCreateTipoBitacoraMutation();
    const [updateTipoBitacora, responseUpdateTipoBitacora] = useUpdateTipoBitacoraMutation();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const descripcionValue = data.get('descripcion');

        const descripcion = `${descripcionValue}`;

        if (!tipoBitacoraData) {
            await crearTipoBitacora({
                variables: {
                    input: {
                        descripcion
                    }
                }
            })
        } else {
            await updateTipoBitacora({
                variables: {
                    input: {
                        id: tipoBitacoraData!.id,
                        descripcion
                    }
                }
            })
        }
    }

    useEffect(() => {
        const close = (!responseCrearTipoBitacora.loading && responseCrearTipoBitacora.data)
            || (!responseUpdateTipoBitacora.loading && responseUpdateTipoBitacora.data);

        if (close) {
            onClose()
        }
    }, [responseCrearTipoBitacora, responseUpdateTipoBitacora, onClose])

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Typography component="h1" variant="h5">
                {tipoBitacoraData ? 'Actualizar Tipo Bitácora' : 'Nuevo Tipo Bitácora'}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    id="descripcion"
                    name="descripcion"
                    label="Descripción"
                    fullWidth
                    autoComplete="off"
                    defaultValue={tipoBitacoraData?.descripcion}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    {tipoBitacoraData ? 'Actualizar' : 'Registrar'}
                </Button>
            </Box>
        </Card>
    )
}

export default FormTipoBitacora;
