import React from 'react';
import Avatar from "@mui/material/Avatar";
import {Edit, RoomService} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {IServicio} from "../../../../models";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

interface IListadoServicios {
    servicios: IServicio[];
    onOpenModal: (servicio: IServicio|undefined) => void;
}

const ListadoServicios = ({ servicios, onOpenModal }: IListadoServicios) => {
    return (
        <Card
            sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Avatar sx={{m: 1}}>
                <RoomService />
            </Avatar>
            <Typography component="h1" variant="h5">
                Servicios
            </Typography>
            {
                servicios.length ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Controles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {servicios.map((servicio: IServicio) => (
                                        <TableRow
                                            key={servicio.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>{servicio.descripcion}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => onOpenModal(servicio)}>
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                    : <div>No hay servicios registrados para esta empresa</div>
            }
            <Button onClick={() => onOpenModal(undefined)}>Agregar servicio</Button>
        </Card>
    );
}

export default ListadoServicios;
