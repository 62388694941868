import * as React from 'react';
import {useContext, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {gql, useMutation} from "@apollo/client";
import {AuthContext, IAuthContext} from "../context/AuthContext";
import {useNavigate} from 'react-router-dom'

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                CSL
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const LOGIN_MUTATION = gql`
    mutation Login($password: String!, $email: String!) {
        login(password: $password, email: $email) {
            token
            userInfo: user {
                id
                email
                empresaID
                roles
            }
            expiresAt
        }
    }
`

export default function SignIn() {

    const navigate = useNavigate();
    const [login, {loading, data, error}] = useMutation(LOGIN_MUTATION)

    const authContext: IAuthContext = useContext(AuthContext)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const password = data.get('password')

        void login({
            variables: {
                email,
                password
            }
        })
    };

    useEffect(() => {
        if (authContext.isAuthenticated()) {
            navigate('/')
        }
    }, [authContext, navigate])

    useEffect(() => {
        if (data) {
            authContext.setAuthState(data.login)
        }
    }, [data, authContext])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Card
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                    {
                        loading && (
                            <div>Cargando...</div>
                        )
                    }
                    {
                        error && (
                            <Typography>
                                {error.message}
                            </Typography>
                        )
                    }

                </Card>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
