import {Archivo, DocumentNames} from "../generated/graphql";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {FilePresent} from "@mui/icons-material";
import React from "react";

export const hasDocumentAttached = (expectedDocuments: DocumentNames[], files: Archivo[]) => {
    return files.filter((file) => (expectedDocuments.includes(file.fileName as DocumentNames)))
};

export const renderDownloadLink = (files: Archivo[]) => {
    return files.map((archivo) => {
        return (
            <Tooltip key={archivo.id} title={archivo.fileName}>
                <a href={`${process.env.REACT_APP_URL}/files?key=${archivo.key}`} target="_blank" rel="noreferrer">
                    <IconButton color="success">
                        <FilePresent />
                    </IconButton>
                </a>
            </Tooltip>
        )
    })
};
