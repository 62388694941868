import React, {useContext, useEffect, useState} from 'react';
import {AuthContext, IAuthContext} from "../../context/AuthContext";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import ListadoFacturas from "./components/ListadoFacturas/ListadoFacturas";

import styles from './Facturacion.module.scss';
import SimpleModal from "../shared/Modals/SimpleModal";
import FormFactura from "./components/FormFactura/FormFactura";
import {Factura, useClientesQuery, useFindFacturasLazyQuery, useServiciosEmpresaQuery} from "../../generated/graphql";
import {useSucursales, useSucursalesField} from "../shared/hooks/sucursales";

import {DateTime} from 'luxon';
import TextField from "@mui/material/TextField";

const Facturacion = () => {

    const { authState, isUser, isAdmin, isFacturacion, isSupervisor } = useContext<IAuthContext>(AuthContext)

    const [desde, setDesde] = useState<string>(DateTime.now().startOf('month').toISODate());
    const [hasta, setHasta] = useState<string>(DateTime.now().endOf('month').toISODate());

    const [empresaId, setEmpresaId] = useState<string>(() => {
        if (!isUser()) {
            return ''
        } else {
            return authState.userInfo!.empresaID;
        }
    });

    const responseClientes = useClientesQuery({
        variables: {
            empresaId: authState.userInfo!.empresaID
        }
    })

    const responseServicios = useServiciosEmpresaQuery({
        variables: {
            empresaId: authState.userInfo!.empresaID
        }
    });

    const responseSucursales = useSucursales(empresaId)

    const {sucursalesSelectField, sucursalId} =
        useSucursalesField(responseSucursales, 'all', true, false, true)

    const [findFacturas, responseFacturas] = useFindFacturasLazyQuery({
        fetchPolicy: "no-cache"
    })

    const [open, setOpen] = useState(false);
    const [factura, setFactura] = useState<Factura|undefined>();
    const handleOpen = (factura: Factura|undefined) => {
        factura && setFactura(factura)
        setOpen(true)
    };

    const handleClose = () => {
        void responseFacturas.refetch();

        setFactura(undefined)
        setOpen(false)
    }

    useEffect(() => {
        if (empresaId) {
            void findFacturas({
                variables: {
                    input: {
                        empresaId,
                        desde,
                        hasta,
                        sucursalId: sucursalId === 'all' ? null : sucursalId
                    }
                }
            })
        }
    }, [empresaId, desde, hasta, sucursalId, findFacturas])

    const loading = responseClientes.loading || responseServicios.loading;
    const error = responseClientes.error || responseServicios.error;

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error al cargar la página</div>;
    }

    const { clientes } = responseClientes.data!;

    const handleChange = (event: SelectChangeEvent) => {
        setEmpresaId(event.target.value)
    }

    return (
        <Box>
            <Box className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Facturación</Typography>
                <Box>

                </Box>
                {
                    (isAdmin() || isFacturacion()) && (
                        <Button
                            variant="contained"
                            onClick={() => handleOpen(undefined)}
                            disabled={!empresaId}
                        >
                            Ingresar Factura
                        </Button>
                    )
                }
            </Box>

            {
                <Box>
                    {
                        (isAdmin() || isFacturacion() || isSupervisor()) && (
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="clientes-label">Clientes</InputLabel>
                                <Select
                                    labelId="clientes-label"
                                    id="clientes"
                                    value={empresaId}
                                    label="Clientes"
                                    onChange={handleChange}
                                >
                                    {
                                        clientes?.map((cliente) => (
                                            <MenuItem key={cliente.id} value={cliente.id}>{cliente.nombre}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        {sucursalesSelectField}
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="desde"
                            name="desde"
                            label="Desde"
                            type="date"
                            value={desde}
                            onChange={(event) => {setDesde(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1}}>
                        <TextField
                            required
                            id="hasta"
                            name="hasta"
                            label="Hasta"
                            type="date"
                            value={hasta}
                            onChange={(event) => {setHasta(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Box>
            }

            <Box>
                <ListadoFacturas
                    data={responseFacturas.data}
                    loading={responseFacturas.loading}
                    error={responseFacturas.error}
                    onOpenModal={handleOpen}
                />
            </Box>
            <SimpleModal open={open} onClose={handleClose}>
                <FormFactura
                    factura={factura}
                    responseSucursales={responseSucursales}
                    isAdmin={isAdmin}
                    isFacturacion={isFacturacion}
                />
            </SimpleModal>
        </Box>
    )
}

export default Facturacion;
