import React from 'react';
import {Box, LinearProgress} from "@mui/material";

const LinearProgressLoader = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    )
}

export default LinearProgressLoader;
