import React from 'react';
import Box from "@mui/material/Box";
import {
    DocumentNames,
    FindIncidentesQuery,
    Incidente,
    useToggleIncidenteEstatusMutation
} from "../../../generated/graphql";
import {ApolloError} from "@apollo/client";
import LinearProgressLoader from "../../shared/Loaders/LinearProgressLoader";
import {Switch, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {DateTime} from "luxon";
import IconButton from "@mui/material/IconButton";
import {Edit, HighlightOff} from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import {hasDocumentAttached, renderDownloadLink} from "../../../lib/archivos";

interface IListadoIncidentes {
    loading: boolean;
    error: ApolloError | undefined;
    data: FindIncidentesQuery | undefined;
    refetch: () => {};
    onOpenModal: (incidente: Incidente) => void;
}

const ListadoIncidentes = ({ loading, data, error, refetch, onOpenModal } : IListadoIncidentes) => {

    const [toggleEstatusAbierto] = useToggleIncidenteEstatusMutation()

    const onToggleIncidenteEstatus = async (id: string) => {
        await toggleEstatusAbierto({
            variables: {
                incidenteId: id
            }
        })

        void refetch()
    }

    if (loading) {
        return <LinearProgressLoader />
    }

    if (!data) {
        return <div>Usa los filtros para cargar los registros de Incidencias</div>
    }

    if (error) {
        return (
            <Box>
                <Typography>
                    Error al cargar los incidentes de la Empresa ;(
                </Typography>
            </Box>
        )
    }

    const { findIncidentes: incidentes } = data!;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sucursal</TableCell>
                        <TableCell>Fecha registro</TableCell>
                        <TableCell>Incidencia</TableCell>
                        <TableCell>Clasificación de riesgo</TableCell>
                        <TableCell>Evidencia</TableCell>
                        <TableCell>Fecha compromiso</TableCell>
                        <TableCell>Acción correctiva</TableCell>
                        <TableCell>Plan de acción</TableCell>
                        <TableCell>Comentarios</TableCell>
                        <TableCell>Estatus</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {incidentes.map((incidente) => (
                        <TableRow
                            key={incidente.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>
                                {incidente.sucursal.nombre}
                            </TableCell>
                            <TableCell>
                                {DateTime.fromISO(incidente.fechaRegistro).toUTC().toFormat('DDD')}
                            </TableCell>
                            <TableCell>{incidente.incidencia}</TableCell>
                            <TableCell>{incidente.categoria}</TableCell>
                            <TableCell>
                                {
                                    incidente.archivos?.length
                                        ? renderDownloadLink(
                                            hasDocumentAttached([DocumentNames.Evidencia], incidente.archivos)
                                        )
                                        : (
                                            <IconButton disabled>
                                                <HighlightOff />
                                            </IconButton>
                                        )
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    incidente.fechaCompromiso
                                        ? DateTime.fromISO(incidente.fechaCompromiso).toUTC().toFormat('DDD')
                                        : 'Pendiente'
                                }
                            </TableCell>
                            <TableCell>{incidente.accion ? incidente.accion : 'Por definir'}</TableCell>
                            <TableCell>
                                {
                                    incidente.archivos?.length
                                        ? renderDownloadLink(
                                            hasDocumentAttached([DocumentNames.Protocolo], incidente.archivos)
                                        )
                                        : (
                                            <IconButton disabled>
                                                <HighlightOff />
                                            </IconButton>
                                        )
                                }
                            </TableCell>
                            <TableCell>
                                {incidente.comentarios}
                            </TableCell>
                            <TableCell>
                                <Switch
                                    defaultChecked={incidente.abierto}
                                    onChange={() => onToggleIncidenteEstatus(incidente.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => onOpenModal(incidente)}>
                                    <Edit />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ListadoIncidentes;
