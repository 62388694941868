import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {CheckCircle} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {
    Bitacora,
    DocumentNames,
    EntityTypes,
    LoadSucursalesQueryResult,
    useActualizarBitacoraMutation, useFindTiposBitacoraQuery,
    useLoadBitacoraByIdLazyQuery,
    useRegistrarBitacoraMutation
} from "../../../generated/graphql";
import {DateTime} from "luxon";
import {ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import LinearProgressLoader from "../../shared/Loaders/LinearProgressLoader";
import Button from "@mui/material/Button";
import {useSucursalesField} from "../../shared/hooks/sucursales";
import {useUploadFile} from "../../shared/hooks/archivos";

interface IFormBitacora {
    empresaId: string;
    responseSucursales: LoadSucursalesQueryResult;
    bitacora?: Bitacora;
}

const FormBitacora = ({ empresaId, responseSucursales, bitacora }: IFormBitacora) => {

    const [bitacoraData, setBitacoraData] = useState<Bitacora|undefined>(bitacora);

    const [registrarBitacora, responseRegistrarBitacora] = useRegistrarBitacoraMutation();
    const [actualizarBitacora, responseActualizarBitacora] = useActualizarBitacoraMutation();
    const [loadBitacora, responseLoadBitacora] = useLoadBitacoraByIdLazyQuery({
        fetchPolicy: "no-cache"
    });

    const responseTiposBitacora = useFindTiposBitacoraQuery();

    const [tipoBitacoraId, setTipoBitacoraId] = useState<string>(bitacoraData?.tipoBitacora.id || '');
    const handleTipoBitacoraChange = (event: SelectChangeEvent) => {
        setTipoBitacoraId(event.target.value)
    }

    const {sucursalesSelectField, sucursalId} =
        useSucursalesField(responseSucursales, bitacoraData?.sucursalId, true)

    const {documents, onInputFileChange, uploadFileResponse} = useUploadFile(bitacoraData?.archivos)
    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await onInputFileChange(event, bitacoraData!.id, EntityTypes.Factura)
    }

    useEffect(() => {
        if (!uploadFileResponse.loading && uploadFileResponse.data) {
            void loadBitacora({
                variables: {
                    bitacoraId: bitacoraData!.id
                }
            })
        }
    }, [uploadFileResponse.loading, uploadFileResponse.data, bitacoraData, loadBitacora])

    useEffect(() => {
        if (!responseRegistrarBitacora.loading && responseRegistrarBitacora.data) {
            setBitacoraData(responseRegistrarBitacora.data.registrarBitacora);
        }

        if (!responseLoadBitacora.loading && responseLoadBitacora.data) {
            setBitacoraData(responseLoadBitacora.data.loadBitacoraById);
        }
    }, [responseRegistrarBitacora, responseLoadBitacora])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const fechaRegistro = data.get('fechaRegistro');
        const personaReporte = data.get('personaReporte');
        const comentarios = data.get('comentarios');

        if (!bitacoraData) {
            await registrarBitacora({
                variables: {
                    input: {
                        empresaId,
                        sucursalId,
                        fechaRegistro,
                        tipoBitacoraId,
                        personaReporte: `${personaReporte}`,
                        comentarios: `${comentarios}`
                    }
                }
            })
        } else {
            const bitacoraId = bitacoraData.id;

            await actualizarBitacora({
                variables: {
                    input: {
                        bitacoraId,
                        empresaId,
                        sucursalId,
                        fechaRegistro,
                        tipoBitacoraId,
                        personaReporte: `${personaReporte}`,
                        comentarios: `${comentarios}`
                    }
                }
            })

            await loadBitacora({
                variables: {
                    bitacoraId
                }
            })
        }
    }

    const fechaRegistro = bitacoraData ? DateTime.fromISO(bitacoraData.fechaRegistro).toUTC().toISODate() : '';

    const loading = responseRegistrarBitacora.loading
        || responseActualizarBitacora.loading
        || responseLoadBitacora.loading
        || uploadFileResponse.loading
        || responseTiposBitacora.loading;

    const error = responseRegistrarBitacora.error
        || responseActualizarBitacora.error
        || responseLoadBitacora.error
        || uploadFileResponse.error
        || responseTiposBitacora.error;

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Typography component="h1" variant="h5">
                {bitacoraData ? 'Actualizar Bitácora' : 'Nueva Bitácora'}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                <FormControl fullWidth>
                    { sucursalesSelectField }
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    id="fechaRegistro"
                    name="fechaRegistro"
                    label="Fecha"
                    type="date"
                    fullWidth
                    defaultValue={fechaRegistro}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel id="servicio-label">Tipo de bitácora</InputLabel>
                    <Select
                        required
                        labelId="servicio-label"
                        id="servicio"
                        name="servicio"
                        value={tipoBitacoraId}
                        label="Servicios"
                        onChange={handleTipoBitacoraChange}
                    >
                        {
                            responseTiposBitacora.data?.findTiposBitacora?.map((tipoBitacora) => (
                                <MenuItem key={tipoBitacora.id} value={tipoBitacora.id}>{tipoBitacora.descripcion}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    id="personaReporte"
                    name="personaReporte"
                    label="Reportó"
                    fullWidth
                    autoComplete="off"
                    defaultValue={bitacoraData?.personaReporte}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    id="comentarios"
                    name="comentarios"
                    label="Comentarios"
                    fullWidth
                    autoComplete="off"
                    defaultValue={bitacoraData?.comentarios}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <ButtonGroup disabled={!bitacoraData} variant="contained" size="small" aria-label="outlined primary button group">
                    <Button component="label" color="primary">
                        Adjunto {documents.includes(DocumentNames.RegistroBitacora) && <CheckCircle />}
                        <input
                            accept="image/*,.pdf"
                            type="file"
                            name={DocumentNames.RegistroBitacora}
                            hidden
                            onChange={onChange}
                        />
                    </Button>
                </ButtonGroup>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    {bitacoraData ? 'Actualizar' : 'Guardar'}
                </Button>
            </Box>

            { loading && <LinearProgressLoader /> }

            {
                error && (
                    <Typography>
                        {error.message}
                    </Typography>
                )
            }
        </Card>
    )
}

export default FormBitacora
