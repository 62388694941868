import React, {useContext, useEffect, useState} from 'react';
import {AuthContext, IAuthContext} from "../../../context/AuthContext";
import {useMutation, useQuery} from "@apollo/client";
import {LOAD_SERVICIOS, LOAD_SUCURSALES} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import {IServicio, ISucursal} from "../../models";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from '@mui/material';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import SimpleModal from "../../shared/Modals/SimpleModal";
import Box from "@mui/material/Box";
import {ASIGNAR_SERVICIOS_SUCURSAL} from "../../../graphql/mutations";
import Typography from "@mui/material/Typography";

export interface IServiciosData {
    serviciosEmpresa: [IServicio] | null
}

export interface ISucursalesData {
    sucursales: [ISucursal]
}

interface IServiciosSucursal {
    sucursalId: string;
    serviciosIds: string[];
}

const Sucursales = () => {

    const { authState, isSupervisor } = useContext<IAuthContext>(AuthContext)

    const { id } = useParams();

    const [updated, setUpdated] = useState(false);
    const [selectedSucursal, setSelectedSucursal] = useState<IServiciosSucursal|undefined>();

    const responseServicios = useQuery<IServiciosData>(LOAD_SERVICIOS, {
        variables: {
            empresaId: authState.userInfo?.empresaID
        }
    });

    const responseSucursales = useQuery<ISucursalesData>(LOAD_SUCURSALES, {
        variables: {
            empresaId: id
        },
        fetchPolicy: "no-cache"
    });

    const [asignarServiciosSucursal, responseAsignarServicios] = useMutation(ASIGNAR_SERVICIOS_SUCURSAL);

    const loading = responseSucursales.loading || responseServicios.loading;

    const error = responseSucursales.error || responseServicios.error;

    useEffect(() => {
        if (responseAsignarServicios.data) {
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false)
                setSelectedSucursal(undefined)
                responseSucursales.refetch()
            }, 2000)
        }
    }, [responseAsignarServicios.data, responseSucursales, setSelectedSucursal])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (loading) {
        return <div>Cargando...</div>
    }

    const { serviciosEmpresa: servicios } = responseServicios.data!;
    const { sucursales } = responseSucursales.data!;

    const selectSucursal = (sucursal: ISucursal | undefined) => {
        if (sucursal) {
            setSelectedSucursal({
                sucursalId: sucursal.id,
                serviciosIds: sucursal.servicios.map((servicio) => (servicio.id))
            })
        } else {
            setSelectedSucursal(undefined)
        }
    }

    const getSelectedSucursal = (sucursalId: string): ISucursal => {
        return sucursales.filter((sucursal) => (sucursal.id === sucursalId))[0]
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkBoxValue = event.target.value;
        if (event.target.checked) {
            setSelectedSucursal({
                sucursalId: selectedSucursal!.sucursalId,
                serviciosIds: selectedSucursal!.serviciosIds.concat(checkBoxValue)
            })
        } else {
            setSelectedSucursal({
                sucursalId: selectedSucursal!.sucursalId,
                serviciosIds: selectedSucursal!.serviciosIds.filter((servicioId) => (servicioId !== checkBoxValue))
            })
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        void asignarServiciosSucursal({
            variables: {
                input: {
                    id: selectedSucursal!.sucursalId,
                    serviciosIds: selectedSucursal!.serviciosIds
                }
            }
        })
    }

    const renderModal = () => {
        const sucursal = getSelectedSucursal(selectedSucursal!.sucursalId)

        return (
            <SimpleModal key={'modal'} open onClose={() => selectSucursal(undefined)}>
                <>
                    <Box>{sucursal.nombre}</Box>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Asignar servicios</FormLabel>
                            <FormGroup>
                                {
                                    servicios?.map((servicio) => (
                                        <FormControlLabel
                                            key={`${sucursal.id}-${servicio.id}`}
                                            control={
                                                <Checkbox
                                                    checked={!!selectedSucursal!.serviciosIds.filter(
                                                        (item) => (item === servicio.id)).length}
                                                    name={servicio.descripcion}
                                                    onChange={handleChange}
                                                    value={servicio.id}
                                                />
                                            }
                                            label={servicio.descripcion}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Guardar
                        </Button>
                        {
                            responseAsignarServicios.loading && (
                                <div>Guardando...</div>
                            )
                        }
                        {
                            responseAsignarServicios.error && (
                                <Typography>
                                    {responseAsignarServicios.error.message}
                                </Typography>
                            )
                        }
                        {
                            updated && <Typography>Cambios guardados!</Typography>
                        }
                    </Box>
                </>
            </SimpleModal>
        )
    }

    return (
        <>
            {
                sucursales.length && (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Ubicación</TableCell>
                                    <TableCell>Estatus</TableCell>
                                    <TableCell>Servicios activos</TableCell>
                                    <TableCell>Controles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sucursales?.map((sucursal) => (
                                    <TableRow
                                        key={sucursal.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{sucursal.nombre}</TableCell>
                                        <TableCell>{sucursal.ubicacion}</TableCell>
                                        <TableCell>{sucursal.activa ? 'Activa' : 'Inactiva'}</TableCell>
                                        <TableCell>
                                            {
                                                sucursal.servicios.length ? sucursal.servicios.map((servicio) => (servicio.descripcion)).join(", ") : "Ninguno"
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button disabled={isSupervisor()} onClick={() => selectSucursal(sucursal)}>Servicios</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
            { selectedSucursal && renderModal() }
        </>
    )
}

export default Sucursales;
