import React, {useEffect} from 'react';
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useMutation} from "@apollo/client";
import {CREATE_SUCURSAL, UPDATE_SUCURSAL} from "../../../../../graphql/mutations";
import {ISucursal} from "../../../../models";
import {LocationOn} from "@mui/icons-material";
import LinearProgressLoader from "../../../../shared/Loaders/LinearProgressLoader";


interface IFormModificarSucursal {
    empresaId: string;
    sucursal?: ISucursal;
    onSaveSuccess?: () => void;
}

const FormSucursal = ({ empresaId, sucursal, onSaveSuccess }: IFormModificarSucursal) => {

    const [agregarSucursal, createResponse] = useMutation(CREATE_SUCURSAL)
    const [modificarSucursal, updateResponse] = useMutation(UPDATE_SUCURSAL)

    const data = createResponse.data || updateResponse.data;
    const loading = createResponse.loading || updateResponse.loading;
    const error = createResponse.error || updateResponse.error;

    useEffect(() => {
        if (data) {
            onSaveSuccess && onSaveSuccess()
        }
    }, [data, onSaveSuccess])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const id = sucursal?.id ?? null;
        const nombre = data.get('nombre');
        const ubicacion = data.get('ubicacion');

        if (id) {
            void modificarSucursal({
                variables: {
                    input: {
                        id,
                        nombre,
                        ubicacion
                    }
                }
            })
        } else {
            void agregarSucursal({
                variables: {
                    input: {
                        empresaId,
                        nombre,
                        ubicacion
                    }
                }
            })
        }
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LocationOn />
                </Avatar>
                <Typography component="h1" variant="h5">
                    { sucursal?.nombre ?? "NUEVA SUCURSAL" }
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        name="nombre"
                        autoFocus
                        autoComplete='off'
                        defaultValue={sucursal?.nombre}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="ubicacion"
                        label="Ubicación"
                        id="ubicacion"
                        autoComplete='off'
                        defaultValue={sucursal?.ubicacion}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Guardar
                    </Button>
                </Box>
                {
                    loading && (
                        <LinearProgressLoader />
                    )
                }
                {
                    error && (
                        <Typography>
                            {error.message}
                        </Typography>
                    )
                }
            </Card>
        </>
    );
}

export default FormSucursal;
