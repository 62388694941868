import React from 'react';
import Box from "@mui/material/Box";
import {Bitacora, DocumentNames, FindBitacorasQuery} from "../../../generated/graphql";
import {ApolloError} from "@apollo/client";
import LinearProgressLoader from "../../shared/Loaders/LinearProgressLoader";
import {Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {DateTime} from "luxon";
import IconButton from "@mui/material/IconButton";
import {Edit, HighlightOff} from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import {hasDocumentAttached, renderDownloadLink} from "../../../lib/archivos";

interface IListadoBitacoras {
    loading: boolean;
    error: ApolloError | undefined;
    data: FindBitacorasQuery | undefined;
    onOpenModal: (bitacora: Bitacora) => void;
    isAdmin: () => boolean;
}

const ListadoBitacoras = ({ loading, data, error, onOpenModal, isAdmin } : IListadoBitacoras) => {

    if (loading) {
        return <LinearProgressLoader />
    }

    if (!data) {
        return <div>Usa los filtros para cargar los registros de Bitácoras</div>
    }

    if (error) {
        return (
            <Box>
                <Typography variant='h6'>
                    Error al intentar cargar los datos:
                </Typography>
                <Typography sx={{fontStyle: 'italic'}}>
                    {error.message}
                </Typography>
            </Box>
        )
    }

    const { findBitacoras: bitacoras } = data!;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sucursal</TableCell>
                        <TableCell>Fecha registro</TableCell>
                        <TableCell>Tipo de bitácora</TableCell>
                        <TableCell>Reportó</TableCell>
                        <TableCell>Comentarios</TableCell>
                        <TableCell>Archivos</TableCell>
                        {
                            isAdmin() && (
                                <TableCell>&nbsp;</TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bitacoras.map((bitacora) => (
                        <TableRow
                            key={bitacora.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>
                                {bitacora.sucursal.nombre}
                            </TableCell>
                            <TableCell>
                                {DateTime.fromISO(bitacora.fechaRegistro).toUTC().toFormat('DDD')}
                            </TableCell>
                            <TableCell>{bitacora.tipoBitacora.descripcion}</TableCell>
                            <TableCell>{bitacora.personaReporte}</TableCell>
                            <TableCell>{bitacora.comentarios}</TableCell>
                            <TableCell>
                                {
                                    bitacora.archivos?.length
                                        ? renderDownloadLink(
                                            hasDocumentAttached([DocumentNames.RegistroBitacora], bitacora.archivos)
                                        )
                                        : (
                                            <IconButton disabled>
                                                <HighlightOff />
                                            </IconButton>
                                        )
                                }
                            </TableCell>
                            {
                                isAdmin() && (
                                    <TableCell>
                                        <IconButton onClick={() => onOpenModal(bitacora)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ListadoBitacoras;
