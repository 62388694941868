import React, {createContext, useState} from 'react';
import {useNavigate} from "react-router-dom";

// @ts-ignore
const AuthContext = createContext<IAuthContext>();
const {Provider} = AuthContext;

export interface AuthInfo {
    token?: string | null;
    userInfo: {
        id: string;
        email: string;
        empresaID: string;
        roles: string[];
    } | null;
    expiresAt?: string | null;
}

export interface IAuthContext {
    authState: AuthInfo;
    isAuthenticated: () => boolean;
    isUser: () => boolean;
    isAdmin: () => boolean;
    isFacturacion: () => boolean;
    isCalidad: () => boolean;
    isSupervisor: () => boolean;
    logout: () => void;
    setAuthState: (authInfo: AuthInfo) => void
}

const AuthProvider = ({children}: any) => {

    const navigate = useNavigate();

    const [authState, setAuthState] = useState<AuthInfo>(() => {
        const token = localStorage.getItem('token')
        const userInfo = localStorage.getItem('userInfo')
        const expiresAt = localStorage.getItem('expiresAt')

        return {
            token,
            userInfo: userInfo ? JSON.parse(userInfo) : null,
            expiresAt
        }
    });

    const setAuthInfo = ({token, userInfo, expiresAt}: AuthInfo) => {
        localStorage.setItem('token', token!!);
        localStorage.setItem('userInfo', JSON.stringify(userInfo!!))
        localStorage.setItem('expiresAt', expiresAt!!)

        setAuthState({
            token,
            userInfo,
            expiresAt
        })
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo')

        setAuthState({
            token: null,
            userInfo: null,
            expiresAt: null
        })

        navigate('/login')
    }

    const isAuthenticated = () => {
        if (!authState.userInfo?.roles || !authState.token || !authState.expiresAt) {
            return false;
        }

        return (new Date().getTime() / 1000 < +authState.expiresAt);
    }

    const isUser = () => {
        return !!authState.userInfo?.roles.some((rol) => rol === 'user')
    }

    const isAdmin = () => {
        return !!authState.userInfo?.roles.some((rol) => rol === 'admin')
    }

    const isSupervisor = () => {
        return !!authState.userInfo?.roles.some((rol) => rol === 'supervisor')
    }

    const isFacturacion = () => {
        return !!authState.userInfo?.roles.some((rol) => rol === 'facturacion')
    }

    const isCalidad = () => {
        return !!authState.userInfo?.roles.some((rol) => rol === 'calidad')
    }

    return (
        <Provider value={{
            authState,
            setAuthState: (authInfo: AuthInfo) => setAuthInfo(authInfo),
            logout,
            isAuthenticated,
            isUser,
            isAdmin,
            isFacturacion,
            isCalidad,
            isSupervisor
        }}>
            {children}
        </Provider>
    )
}

export {AuthContext, AuthProvider};
