import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {ButtonGroup, FormControl} from "@mui/material";
import {DateTime} from "luxon";
import {CheckCircle} from "@mui/icons-material";
import LinearProgressLoader from "../../../shared/Loaders/LinearProgressLoader";
import {
    DocumentNames,
    Factura,
    EntityTypes,
    LoadSucursalesQueryResult,
    useCrearFacturaMutation,
    useFacturaByIdLazyQuery,
    useUpdateFacturaMutation
} from "../../../../generated/graphql";
import {useServiciosSucursalesField, useSucursalesField} from "../../../shared/hooks/sucursales";

import styles from './FormFactura.module.scss';
import {useUploadFile} from "../../../shared/hooks/archivos";

interface IFormFactura {
    responseSucursales: LoadSucursalesQueryResult;
    factura?: Factura;
    isAdmin: () => boolean;
    isFacturacion: () => boolean;
}

const FormFactura = ({ responseSucursales, factura, isAdmin, isFacturacion }: IFormFactura) => {

    const [facturaData, setFacturaData] = useState<Factura|undefined>(factura);

    const {sucursalesSelectField, sucursalId, selectedSucursal} =
        useSucursalesField(responseSucursales, facturaData?.sucursalId, true, (!isAdmin() && !isFacturacion()))
    const {serviciosSelectField, servicioId} = useServiciosSucursalesField(facturaData?.servicio.id, selectedSucursal)

    const {documents, onInputFileChange, uploadFileResponse} = useUploadFile(facturaData?.archivos)
    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await onInputFileChange(event, facturaData!.id, EntityTypes.Factura)
    }

    const [loadFactura, loadFacturaResponse] = useFacturaByIdLazyQuery();
    const [crearFactura, crearFacturaResponse] = useCrearFacturaMutation();
    const [updateFactura, updateFacturaResponse] = useUpdateFacturaMutation();

    const loading = crearFacturaResponse.loading
        || loadFacturaResponse.loading
        || updateFacturaResponse.loading
        || uploadFileResponse.loading;

    const error = crearFacturaResponse.error
        || loadFacturaResponse.error
        || updateFacturaResponse.error
        || uploadFileResponse.error;

    useEffect(() => {
        if (!crearFacturaResponse.loading && crearFacturaResponse.data) {
            setFacturaData(crearFacturaResponse.data.crearFactura);
        }

        if (!loadFacturaResponse.loading && loadFacturaResponse.data) {
            setFacturaData(loadFacturaResponse.data.facturaById);
        }
    }, [crearFacturaResponse, loadFacturaResponse])

    useEffect(() => {
        if (!uploadFileResponse.loading && uploadFileResponse.data) {
            void loadFactura({
                variables: {
                    facturaId: facturaData!.id
                }
            })
        }
    }, [uploadFileResponse.loading, uploadFileResponse.data, facturaData, loadFactura])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const desde = data.get('desde');
        const hasta = data.get('hasta');
        const folio = data.get('folio');
        const monto = Number(data.get('monto'));

        if (!facturaData) {
            await crearFactura({
                variables: {
                    input: {
                        desde,
                        hasta,
                        folio: `${folio}`,
                        monto,
                        sucursalId,
                        servicioId
                    }
                }
            })
        } else {
            await updateFactura({
                variables: {
                    input: {
                        facturaId: facturaData.id,
                        desde,
                        hasta,
                        folio: folio ? `${folio}` : facturaData.folio,
                        monto: monto ? monto : facturaData.monto,
                        sucursalId,
                        servicioId
                    }
                }
            })
        }

        if (facturaData) {
            await loadFactura({
                variables: {
                    facturaId: facturaData.id
                }
            })
        }
    }

    const desde = facturaData ? DateTime.fromISO(facturaData.desde).toUTC().toISODate() : '';
    const hasta = facturaData ? DateTime.fromISO(facturaData.hasta).toUTC().toISODate() : '';

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Typography component="h1" variant="h5">
                {factura ? 'Actualizar Factura' : 'Nueva Factura'}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                <FormControl fullWidth sx={{mb: 1}}>
                    { sucursalesSelectField }
                </FormControl>
                <FormControl fullWidth>
                    { serviciosSelectField }
                </FormControl>
                <Box
                    sx={{
                        '& .MuiTextField-root': {m: 1, mt: 2, width: '27ch'},
                    }}
                >
                    <TextField
                        margin="normal"
                        required
                        id="desde"
                        name="desde"
                        label="Desde"
                        type="date"
                        defaultValue={desde}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        id="hasta"
                        name="hasta"
                        label="Hasta"
                        type="date"
                        defaultValue={hasta}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                {
                    (isAdmin() || isFacturacion()) && (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="folio"
                                name="folio"
                                label="Folio"
                                autoComplete='off'
                                defaultValue={facturaData?.folio}
                            />
                            <TextField
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                margin="normal"
                                required
                                fullWidth
                                id="monto"
                                name="monto"
                                label="Monto"
                                autoComplete='off'
                                defaultValue={facturaData?.monto}
                            />
                        </>
                    )
                }
                <Box className={styles.Buttons}>
                    <ButtonGroup
                        disabled={!facturaData}
                        variant="contained"
                        size="small"
                        sx={{mt:1}}
                    >
                        <Button component="label" color="primary" disabled={loading || !!error}>
                            Orden de compra {documents.includes(DocumentNames.OrdenDeCompra) && <CheckCircle />}
                            <input
                                accept="image/*,.pdf"
                                type="file"
                                name={DocumentNames.OrdenDeCompra}
                                hidden onChange={onChange}
                            />
                        </Button>
                        <Button component="label" color="primary" disabled={loading || !!error}>
                            Soporte de Cliente {documents.includes(DocumentNames.SoporteDeCliente) && <CheckCircle />}
                            <input
                                accept="image/*,.pdf"
                                type="file"
                                name={DocumentNames.SoporteDeCliente}
                                hidden
                                onChange={onChange}
                            />
                        </Button>
                        {
                            (isAdmin() || isFacturacion()) && (
                                <Button component="label" color="primary" disabled={loading || !!error}>
                                    Factura PDF {documents.includes(DocumentNames.FacturaPdf) && <CheckCircle />}
                                    <input
                                        accept=".pdf"
                                        type="file"
                                        name={DocumentNames.FacturaPdf}
                                        hidden
                                        onChange={onChange}
                                    />
                                </Button>
                            )
                        }
                    </ButtonGroup>
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={loading || !!error}
                >
                    {facturaData ? 'Actualizar' : 'Guardar'}
                </Button>
            </Box>

            { loading && <LinearProgressLoader /> }

            {
                error && (
                    <Typography>
                        {error.message}
                    </Typography>
                )
            }
        </Card>
    )
}

export default FormFactura;
