import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useMutation} from "@apollo/client";
import {ALTA_SERVICIO, MODIFICAR_SERVICIO} from "../../../../../graphql/mutations";
import {IServicio} from "../../../../models";
import {RoomService} from "@mui/icons-material";


interface IFormServicio {
    empresaId: string;
    servicio?: IServicio;
    onSaveSuccess?: () => void;
}

const FormServicio = ({empresaId, servicio, onSaveSuccess}: IFormServicio) => {

    const [updated, setUpdated] = useState(false);

    const [altaServicio, responseAltaServicio] = useMutation(ALTA_SERVICIO);
    const [modificarServicio, responseModificarServicio] = useMutation(MODIFICAR_SERVICIO);

    const data = responseAltaServicio.data || responseModificarServicio.data;
    const error = responseAltaServicio.error || responseModificarServicio.error;
    const loading = responseAltaServicio.loading || responseModificarServicio.loading;

    useEffect(() => {
        if (data) {
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false)
                onSaveSuccess && onSaveSuccess();
            }, 3000)
        }
    }, [data, onSaveSuccess])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const id = servicio?.id ?? null;
        const descripcion = data.get('descripcion');

        if (id) {
            void modificarServicio({
                variables: {
                    input: {
                        id,
                        descripcion,
                    }
                }
            })
        } else {
            void altaServicio({
                variables: {
                    input: {
                        descripcion,
                        empresaId
                    }
                }
            })
        }
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <RoomService />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {servicio?.descripcion ?? "NUEVO SERVICIO"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="descripcion"
                        label="Descripcion"
                        name="descripcion"
                        autoFocus
                        autoComplete='off'
                        defaultValue={servicio?.descripcion}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Guardar
                    </Button>
                </Box>
                {
                    loading && (
                        <div>Guardando...</div>
                    )
                }
                {
                    error && (
                        <Typography>
                            {error.message}
                        </Typography>
                    )
                }
                {
                    updated && <Typography>Cambios guardados!</Typography>
                }
            </Card>
        </>
    );
}

export default FormServicio;
