import React from 'react';
import {ApolloError} from "@apollo/client";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {DateTime} from "luxon";
import {Edit, HighlightOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {DocumentNames, Factura, FindFacturasQuery} from "../../../../generated/graphql";
import LinearProgressLoader from "../../../shared/Loaders/LinearProgressLoader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {hasDocumentAttached, renderDownloadLink} from "../../../../lib/archivos";

interface IListadoFacturas {
    loading: boolean;
    data?: FindFacturasQuery;
    error?: ApolloError;
    onOpenModal: (factura: Factura|undefined) => void;
}

const ListadoFacturas = ({loading, data, error, onOpenModal}: IListadoFacturas) => {

    if (loading) {
        return <LinearProgressLoader />
    }

    if (error) {
        return (
            <Box>
                <Typography variant='h6'>
                    Error al intentar cargar los datos:
                </Typography>
                <Typography sx={{fontStyle: 'italic'}}>
                    {error.message}
                </Typography>
            </Box>
        )
    }

    if (!data) {
        return <div>Usa los filtros para cargar los registros de Facturación</div>
    }

    const { findFacturas: facturas } = data

    if (!facturas.length) {
        return <div>No existen facturas registradas con estos criterios</div>
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sucursal</TableCell>
                            <TableCell>Desde</TableCell>
                            <TableCell>Hasta</TableCell>
                            <TableCell>Folio</TableCell>
                            <TableCell>Servicio</TableCell>
                            <TableCell>Monto</TableCell>
                            <TableCell>OC</TableCell>
                            <TableCell>SP</TableCell>
                            <TableCell>FAC</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {facturas.map((factura) => (
                            <TableRow
                                key={factura.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{factura.sucursal.nombre}</TableCell>
                                <TableCell>{DateTime.fromISO(factura.desde).toUTC().toFormat('DDD')}</TableCell>
                                <TableCell>{DateTime.fromISO(factura.hasta).toUTC().toFormat('DDD')}</TableCell>
                                <TableCell>{factura.folio}</TableCell>
                                <TableCell>{factura.servicio.descripcion}</TableCell>
                                <TableCell>{factura.monto}</TableCell>
                                <TableCell>
                                    {
                                        factura.archivos?.length
                                            ? renderDownloadLink(
                                                hasDocumentAttached([DocumentNames.OrdenDeCompra], factura.archivos)
                                            )
                                            : (
                                                <IconButton disabled>
                                                    <HighlightOff />
                                                </IconButton>
                                            )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        factura.archivos?.length
                                            ? renderDownloadLink(
                                                hasDocumentAttached([DocumentNames.SoporteDeCliente], factura.archivos)
                                            )
                                            : (
                                                <IconButton disabled>
                                                    <HighlightOff />
                                                </IconButton>
                                            )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        factura.archivos?.length
                                            ? renderDownloadLink(
                                                hasDocumentAttached([DocumentNames.FacturaPdf], factura.archivos)
                                            )
                                            : (
                                                <IconButton disabled>
                                                    <HighlightOff />
                                                </IconButton>
                                            )
                                    }
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onOpenModal(factura)}>
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ListadoFacturas
