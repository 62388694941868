import {gql} from "@apollo/client";

export const LOAD_EMPRESA_QUERY = gql`
    query LoadEmpresa($empresaId: String!) {
        empresa(empresaId: $empresaId) {
            id
            nombre
            rfc
            activa
            tipo
        }
        sucursales(empresaId: $empresaId) {
            id
            nombre
            ubicacion
            activa
        }
        serviciosEmpresa(empresaId: $empresaId) {
            id
            descripcion
        }
    }
`

export const LOAD_SUCURSALES = gql`
    query LoadSucursales($empresaId: String!) {
        sucursales(empresaId: $empresaId) {
            id
            nombre
            ubicacion
            activa
            servicios {
                id
                descripcion
            }
        }
    }
`

export const LOAD_SERVICIOS = gql`
    query LoadServicios($empresaId: String!) {
        serviciosEmpresa(empresaId: $empresaId) {
            id
            descripcion
        }
    }
`

export const ALL_EMPRESAS_QUERY = gql`
    query AllEmpresas {
        allEmpresas {
            id
            nombre
            rfc
            tipo
            activa
        }
    }
`

export const CLIENTES_QUERY = gql`
    query Clientes($empresaId: String!) {
        clientes(empresaId: $empresaId) {
            id
            nombre
            activa
            rfc
        },
        otrasEmpresas(empresaId: $empresaId) {
            id
            nombre
            activa
            rfc
        }
    }
`
