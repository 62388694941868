import React, {useContext, useState} from 'react';
import {useQuery} from "@apollo/client";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";

import styles from './Empresas.module.scss';
import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import {ALL_EMPRESAS_QUERY} from "../../graphql/queries";
import {IEmpresa} from "../models";
import SimpleModal from "../shared/Modals/SimpleModal";
import FormEmpresa from "./Modificar/components/FormEmpresa/FormEmpresa";
import {AuthContext, IAuthContext} from "../../context/AuthContext";

interface IEmpresasData {
    allEmpresas: [IEmpresa]
}

const Empresas = () => {

    const { authState, isAdmin, isFacturacion } = useContext<IAuthContext>(AuthContext)

    const {data, loading, error, refetch} = useQuery(ALL_EMPRESAS_QUERY, {
        fetchPolicy: "no-cache"
    })

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        void refetch()
        setOpen(false)
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (loading) {
        return <div>Cargando...</div>
    }

    const renderData = (data: IEmpresasData) => {
        const { allEmpresas: empresas } = data;

        return (
            (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>RFC</TableCell>
                                    <TableCell>Estatus</TableCell>
                                    <TableCell size={'small'}>Controles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {empresas.map((empresa) => (
                                    <TableRow
                                        key={empresa.nombre}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{empresa.nombre}</TableCell>
                                        <TableCell>{empresa.rfc}</TableCell>
                                        <TableCell>{empresa.activa ? 'Activa' : 'Inactiva'}</TableCell>
                                        <TableCell>
                                            <IconButton component={Link} to={`/empresas/modificar/${empresa.id}`}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
        )
    }

    return (
        <>
            <div className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Empresas</Typography>
                {
                    (isAdmin() || isFacturacion()) && (
                        <Button variant="contained" onClick={handleOpen}>Nueva</Button>
                    )
                }
            </div>
            {data && renderData(data)}
            <SimpleModal open={open} onClose={handleClose}>
                <FormEmpresa onSaveSuccess={handleClose}/>
            </SimpleModal>
        </>
    )
}

export default Empresas
