import React, {useContext} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {AuthContext, IAuthContext} from "../../context/AuthContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Typography} from "@mui/material";

import styles from './Clientes.module.scss';
import {CLIENTES_QUERY} from "../../graphql/queries";
import {IEmpresa} from "../models";
import {ALTA_CLIENTE, BAJA_CLIENTE} from "../../graphql/mutations";
import {Link} from "react-router-dom";

export interface IClientesData {
    clientes: [IEmpresa];
    otrasEmpresas: [IEmpresa];
}

const Clientes = () => {

    const { authState, isSupervisor } = useContext<IAuthContext>(AuthContext)

    const responseClientes = useQuery<IClientesData>(CLIENTES_QUERY, {
        variables: {
            empresaId: authState.userInfo?.empresaID
        },
        fetchPolicy: "no-cache"
    })

    const [altaCliente] = useMutation(ALTA_CLIENTE);
    const [bajaCliente] = useMutation(BAJA_CLIENTE);

    const handleAltaCliente = async (posibleCliente: IEmpresa) => {
        await altaCliente({
            variables: {
                empresaId: authState.userInfo?.empresaID!,
                clienteId: posibleCliente.id
            }
        });

        await responseClientes.refetch();
    }

    const handleBajaCliente = async (cliente: IEmpresa) => {
        await bajaCliente({
            variables: {
                empresaId: authState.userInfo?.empresaID!,
                clienteId: cliente.id
            }
        });

        await responseClientes.refetch();
    }

    const error = responseClientes.error;
    const loading = responseClientes.loading;

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (loading) {
        return <div>Cargando...</div>
    }

    const { clientes, otrasEmpresas } = responseClientes.data!;

    return (
        <>
            <div className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Clientes</Typography>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>RFC</TableCell>
                            <TableCell>Estatus</TableCell>
                            <TableCell>Sucursales</TableCell>
                            <TableCell>Controles</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientes?.map((cliente) => (
                            <TableRow
                                key={cliente.nombre}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{cliente.nombre}</TableCell>
                                <TableCell>{cliente.rfc}</TableCell>
                                <TableCell>{cliente.activa ? 'Activa' : 'Inactiva'}</TableCell>
                                <TableCell>
                                    <Button component={Link} to={`/clientes/${cliente.id}/sucursales`}>Sucursales</Button>
                                </TableCell>

                                <TableCell>
                                    <Button disabled={isSupervisor()} onClick={() => handleBajaCliente(cliente)}>Baja</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'} mt={'30px'}>Otras empresas</Typography>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>RFC</TableCell>
                            <TableCell>Estatus</TableCell>
                            <TableCell>Controles</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {otrasEmpresas?.map((posibleCliente) => (
                            <TableRow
                                key={posibleCliente.nombre}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{posibleCliente.nombre}</TableCell>
                                <TableCell>{posibleCliente.rfc}</TableCell>
                                <TableCell>{posibleCliente.activa ? 'Activa' : 'Inactiva'}</TableCell>
                                <TableCell>
                                    <Button disabled={isSupervisor()} onClick={() => handleAltaCliente(posibleCliente)}>Alta</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Clientes
