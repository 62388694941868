import React from 'react';
import Avatar from "@mui/material/Avatar";
import {Edit, LocationOn} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ISucursal} from "../../../../models";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {Switch} from "@mui/material";
import {useMutation} from "@apollo/client";
import {ACTIVAR_DESACTIVAR_SUCURSAL} from "../../../../../graphql/mutations";

interface IListadoSucursales {
    sucursales: ISucursal[];
    onOpenModal: (sucursal: ISucursal|undefined) => void;
}

const ListadoSucursales = ({sucursales, onOpenModal}: IListadoSucursales) => {
    const [activarDesactivarSucursal] = useMutation(ACTIVAR_DESACTIVAR_SUCURSAL);

    const onToggleSucursalStatus = async (sucursal: ISucursal) => {
        await activarDesactivarSucursal({
            variables: {
                sucursalId: sucursal.id
            }
        })
    }

    return (
        <Card
            sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Avatar sx={{m: 1}}>
                <LocationOn/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Sucursales
            </Typography>
            {
                sucursales.length ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Ubicación</TableCell>
                                    <TableCell>Estatus</TableCell>
                                    <TableCell>Controles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sucursales.map((sucursal: ISucursal) => (
                                    <TableRow
                                        key={sucursal.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{sucursal.nombre}</TableCell>
                                        <TableCell>{sucursal.ubicacion}</TableCell>
                                        <TableCell>
                                            <Switch
                                                defaultChecked={sucursal.activa}
                                                onChange={() => onToggleSucursalStatus(sucursal)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => onOpenModal(sucursal)}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : <div>No hay sucursales registradas para esta empresa</div>
            }
            <Button onClick={() => onOpenModal(undefined)}>Agregar sucursal</Button>
        </Card>
    );
}

export default ListadoSucursales;
