import React, {useEffect, useState} from 'react';
import {LoadSucursalesQueryResult, Sucursal, useLoadSucursalesLazyQuery} from "../../../generated/graphql";
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

export function useSucursales(empresaId: string) {

    const [loadSucursales, responseSucursales] = useLoadSucursalesLazyQuery();

    useEffect(() => {
        if (empresaId) {
            void loadSucursales({
                variables: {
                    empresaId
                }
            })
        }
    }, [empresaId, loadSucursales])

    return responseSucursales;
}

export function useSucursalesField(
    responseSucursales: LoadSucursalesQueryResult,
    preSelectedSucursal: string = '',
    required: boolean = false,
    disabled: boolean = false,
    includeTodas: boolean = false
) {
    const [sucursalId, setSucursalId] = useState<string>(preSelectedSucursal);
    const [selectedSucursal, setSelectedSucursal] = useState<Sucursal | undefined>();

    const handleSucursalChange = (event: SelectChangeEvent) => {
        setSucursalId(event.target.value)
    }

    useEffect(() => {
        if (sucursalId && responseSucursales.data) {
            setSelectedSucursal(responseSucursales.data.sucursales.find((sucursal) => {
                return sucursal.id === sucursalId
            }))
        }
    }, [sucursalId, responseSucursales.data])

    const sucursalesSelectField = (
        <>
            <InputLabel id="sucursales-label">Sucursales</InputLabel>
            <Select
                id="sucursales"
                required={required}
                value={sucursalId}
                label="Sucursales"
                onChange={handleSucursalChange}
                disabled={disabled || responseSucursales.loading || !responseSucursales.data?.sucursales.length}
            >
                {
                    includeTodas && (
                        <MenuItem key={'all'} value={'all'}>{'Todas'}</MenuItem>
                    )
                }
                {
                    responseSucursales.data && (
                        responseSucursales.data.sucursales.map((sucursal) => (
                            <MenuItem key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</MenuItem>
                        ))
                    )
                }
            </Select>
        </>
    )

    return {
        sucursalId,
        setSucursalId,
        selectedSucursal,
        sucursalesSelectField
    }
}

export function useServiciosSucursalesField(preSelectedServicio: string = '', selectedSucursal: Sucursal | undefined) {
    const [servicioId, setServicioId] = useState<string>(preSelectedServicio);

    const handleServicioChange = (event: SelectChangeEvent) => {
        setServicioId(event.target.value)
    }

    const serviciosSelectField = (
        <>
            <InputLabel id="servicio-label">Servicios</InputLabel>
            <Select
                required
                labelId="servicio-label"
                id="servicio"
                name="servicio"
                value={servicioId}
                label="Servicios"
                onChange={handleServicioChange}
            >
                {
                    selectedSucursal?.servicios?.map((servicio) => (
                        <MenuItem key={servicio.id} value={servicio.id}>{servicio.descripcion}</MenuItem>
                    ))
                }
            </Select>
        </>
    )

    return {
        servicioId,
        serviciosSelectField
    }
}
