import React, {useContext, useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {CheckCircle} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {
    CategoriasIncidente,
    DocumentNames,
    Empresa,
    EntityTypes,
    Incidente,
    useCreateIncidenteMutation,
    useLoadIncidenteByIdLazyQuery,
    useUpdateIncidenteMutation
} from "../../../generated/graphql";
import {DateTime} from "luxon";
import {ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import LinearProgressLoader from "../../shared/Loaders/LinearProgressLoader";
import Button from "@mui/material/Button";
import {AuthContext, IAuthContext} from "../../../context/AuthContext";
import {useSucursales, useSucursalesField} from "../../shared/hooks/sucursales";
import {useUploadFile} from "../../shared/hooks/archivos";

interface IFormIncidente {
    clientes: Empresa[]
    incidente?: Incidente;
}

const categoriasIncidente: CategoriasIncidente[] = [
    CategoriasIncidente.Bajo,
    CategoriasIncidente.Medio,
    CategoriasIncidente.Alto
]

const FormIncidente = ({ clientes, incidente }: IFormIncidente) => {

    const { authState, isAdmin } = useContext<IAuthContext>(AuthContext)

    const [incidenteData, setIncidenteData] = useState<Incidente|undefined>(incidente);

    const [registrarIncidente, responseRegistrarIncidente] = useCreateIncidenteMutation();
    const [actualizarIncidente, responseActualizarIncidente] = useUpdateIncidenteMutation();
    const [loadIncidente, responseLoadIncidente] = useLoadIncidenteByIdLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [empresaId, setEmpresaId] = useState<string>(() => {
        if (incidente) {
            return incidente.empresaId;
        } else if (!isAdmin())  {
            return authState.userInfo!.empresaID;
        } else {
            return '';
        }
    });

    const handleChange = (event: SelectChangeEvent) => {
        setEmpresaId(event.target.value)
        setSucursalId('')
    }

    const [categoriaIncidente, setCategoriaIncidente] = useState<CategoriasIncidente>(CategoriasIncidente.Bajo);
    const handleCategoriaChange = (event: SelectChangeEvent) => {
        setCategoriaIncidente(event.target.value as CategoriasIncidente)
    }

    const responseSucursales = useSucursales(empresaId)
    const {sucursalesSelectField, sucursalId, setSucursalId} =
        useSucursalesField(responseSucursales, incidenteData?.sucursalId, true)

    const {documents, onInputFileChange, uploadFileResponse} = useUploadFile(incidenteData?.archivos)
    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await onInputFileChange(event, incidenteData!.id, EntityTypes.Incidente)
    }

    useEffect(() => {
        if (!responseRegistrarIncidente.loading && responseRegistrarIncidente.data) {
            setIncidenteData(responseRegistrarIncidente.data.createIncidente);
        }

        if (!responseLoadIncidente.loading && responseLoadIncidente.data) {
            setIncidenteData(responseLoadIncidente.data.loadIncidenteById);
        }

        if (!responseActualizarIncidente.loading && responseActualizarIncidente.data) {
            setIncidenteData(responseActualizarIncidente.data.updateIncidente);
        }
    }, [responseRegistrarIncidente, responseActualizarIncidente, responseLoadIncidente])

    useEffect(() => {
        if (!uploadFileResponse.loading && uploadFileResponse.data) {
            void loadIncidente({
                variables: {
                    incidenteId: incidenteData!.id
                }
            })
        }
    }, [uploadFileResponse.loading, uploadFileResponse.data, incidenteData, loadIncidente])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const fechaRegistro = data.get('fechaRegistro');
        const incidencia = data.get('incidencia');
        const fechaCompromiso = data.get('fechaCompromiso');
        const accion = data.get('accion');
        const comentarios = data.get('comentarios');

        if (!incidenteData) {
            await registrarIncidente({
                variables: {
                    input: {
                        empresaId,
                        sucursalId,
                        fechaRegistro,
                        incidencia: `${incidencia}`,
                        fechaCompromiso,
                        accion: accion ? `${accion}` : '',
                        categoria: categoriaIncidente,
                        comentarios: comentarios ? `${comentarios}` : ''
                    }
                }
            })
        } else {
            const incidenteId = incidenteData.id;

            await actualizarIncidente({
                variables: {
                    input: {
                        incidenteId,
                        empresaId,
                        sucursalId,
                        fechaRegistro,
                        incidencia: `${incidencia}`,
                        fechaCompromiso,
                        accion: accion ? `${accion}` : '',
                        categoria: categoriaIncidente,
                        comentarios: comentarios ? `${comentarios}` : ''
                    }
                }
            })

            await loadIncidente({
                variables: {
                    incidenteId
                }
            })
        }
    }

    const fechaRegistro = incidenteData ? DateTime.fromISO(incidenteData.fechaRegistro).toUTC().toISODate() : '';
    const fechaCompromiso = incidenteData ? DateTime.fromISO(incidenteData.fechaCompromiso).toUTC().toISODate() : '';

    const loading = responseRegistrarIncidente.loading
        || responseActualizarIncidente.loading
        || responseLoadIncidente.loading
        || uploadFileResponse.loading;
    const error = responseRegistrarIncidente.error
        || responseActualizarIncidente.error
        || responseLoadIncidente.error
        || uploadFileResponse.error;

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}
        >
            <Typography component="h1" variant="h5">
                {incidenteData ? 'Actualizar Incidente' : 'Nuevo Incidente'}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                {
                    isAdmin() && (
                        <FormControl fullWidth>
                            <InputLabel id="clientes-label">Clientes</InputLabel>
                            <Select
                                labelId="clientes-label"
                                id="clientes"
                                value={empresaId}
                                label="Clientes"
                                onChange={handleChange}
                            >
                                {
                                    clientes && clientes.map((cliente) => (
                                        <MenuItem key={cliente.id} value={cliente.id}>{cliente.nombre}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    )
                }

                <FormControl fullWidth sx={{mt: 2}}>
                    { sucursalesSelectField }
                </FormControl>

                <FormControl fullWidth sx={{mt: 2}}>
                    <InputLabel id="categoria-label">Categoría</InputLabel>
                    <Select
                        labelId="categoria-label"
                        id="categoria"
                        label="Categoría"
                        value={categoriaIncidente}
                        onChange={handleCategoriaChange}
                    >
                        {
                            categoriasIncidente.map((categoria) => (
                                <MenuItem key={categoria} value={categoria}>{categoria}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <TextField
                    margin="normal"
                    required
                    id="fechaRegistro"
                    name="fechaRegistro"
                    label="Fecha"
                    type="date"
                    fullWidth
                    defaultValue={fechaRegistro}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    id="incidencia"
                    name="incidencia"
                    label="Incidencia"
                    fullWidth
                    autoComplete="off"
                    defaultValue={incidenteData?.incidencia}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {
                    isAdmin() && (
                        <>
                            <TextField
                                margin="normal"
                                id="fechaCompromiso"
                                name="fechaCompromiso"
                                label="Fecha Compromiso"
                                type="date"
                                fullWidth
                                defaultValue={fechaCompromiso}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                margin="normal"
                                id="accion"
                                name="accion"
                                label="Acción"
                                fullWidth
                                autoComplete="off"
                                defaultValue={incidenteData?.accion}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                margin="normal"
                                id="comentarios"
                                name="comentarios"
                                label="Comentarios"
                                fullWidth
                                autoComplete="off"
                                defaultValue={incidenteData?.comentarios}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <ButtonGroup disabled={!incidenteData} variant="contained" size="small" aria-label="outlined primary button group">
                                <Button component="label" color="primary">
                                    Nuevo Protocolo {documents.includes(DocumentNames.Protocolo) && <CheckCircle />}
                                    <input accept="image/*,.pdf" type="file" name={DocumentNames.Protocolo} hidden onChange={onChange}/>
                                </Button>
                            </ButtonGroup>
                        </>
                    )
                }
                {
                    !isAdmin() && (
                        <>
                            <ButtonGroup disabled={!incidenteData} variant="contained" size="small" aria-label="outlined primary button group">
                                <Button component="label" color="primary">
                                    Evidencia {documents.includes(DocumentNames.Evidencia) && <CheckCircle />}
                                    <input accept="image/*,.pdf" type="file" name={DocumentNames.Evidencia} hidden onChange={onChange}/>
                                </Button>
                            </ButtonGroup>
                        </>
                    )
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    {incidenteData ? 'Actualizar' : 'Guardar'}
                </Button>
            </Box>

            { loading && <LinearProgressLoader /> }

            {
                error && (
                    <Typography>
                        {error.message}
                    </Typography>
                )
            }
        </Card>
    )
}

export default FormIncidente;
