import React from 'react';
import {Dialog, DialogContent} from "@mui/material";

interface ISimpleModal {
    open: boolean;
    children: JSX.Element;
    onClose: () => void;
}

const SimpleModal = ({open, children, onClose}: ISimpleModal) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default SimpleModal;
