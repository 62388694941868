import React, {useContext, useEffect} from 'react';
import ClippedDrawer from "./components/ClippedDrawer";
import {AuthContext, IAuthContext} from "./context/AuthContext";
import {useNavigate} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessIcon from "@mui/icons-material/Business";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {Link} from 'react-router-dom'
import {Outlet} from "react-router-dom";
import {Report, Store} from "@mui/icons-material";
import {ListItemButton} from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinearProgressLoader from "./components/shared/Loaders/LinearProgressLoader";


const Authenticated = ({children}: any) => {

    const {isAuthenticated, isUser, isAdmin, isFacturacion, isSupervisor, logout} = useContext<IAuthContext>(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated()) {
            logout()
            navigate('/login')
        }
    }, [isAuthenticated, navigate, logout])

    if (!isAuthenticated()) {
        return <LinearProgressLoader />
    }

    const sidebar = (
        <>
            <List>
                {
                    isAuthenticated() && (isAdmin() || isFacturacion() || isSupervisor())  && (
                        <>
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to={'/empresas'}>
                                    <ListItemIcon>
                                        <BusinessIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={'Empresas'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to={'/clientes'}>
                                    <ListItemIcon>
                                        <Store />
                                    </ListItemIcon>
                                    <ListItemText primary={'Clientes'} />
                                </ListItemButton>
                            </ListItem>
                        </>
                    )
                }

                {
                    isAuthenticated() && (isAdmin() || isFacturacion() || isUser() || isSupervisor())  && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={'/facturacion'}>
                                <ListItemIcon>
                                    <AttachMoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Facturación'} />
                            </ListItemButton>
                        </ListItem>
                    )
                }

                {
                    isAuthenticated() && (!isFacturacion()) && (
                        <>
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to={'/incidentes'}>
                                    <ListItemIcon>
                                        <Report />
                                    </ListItemIcon>
                                    <ListItemText primary={'Incidentes'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to={'/bitacoras'}>
                                    <ListItemIcon>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={'Bitacoras'} />
                                </ListItemButton>
                            </ListItem>
                        </>
                    )
                }
                <Divider/>
            </List>
        </>
    )

    return (
        <ClippedDrawer sidebar={sidebar}>
            <Outlet />
        </ClippedDrawer>
    );
}

export default Authenticated;
