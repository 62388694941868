import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import FormEmpresa from "./components/FormEmpresa/FormEmpresa";
import {Grid} from "@mui/material";
import {useQuery} from "@apollo/client";
import {LOAD_EMPRESA_QUERY} from "../../../graphql/queries";
import {IServicio, ISucursal} from "../../models";
import FormSucursal from "./components/FormSucursal/FormSucursal";
import SimpleModal from "../../shared/Modals/SimpleModal";
import ListadoSucursales from "./components/ListadoSucursales/ListadoSucursales";
import ListadoServicios from "./components/ListadoServicios/ListadoServicios";
import FormServicio from "./components/FormServicio/FormServicio";

const ModificarEmpresa = () => {
    let {id} = useParams();

    const {data, loading, error, refetch} = useQuery(LOAD_EMPRESA_QUERY, {
        variables: {
            empresaId: id
        },
        fetchPolicy: "no-cache"
    })

    const [modalSucursalOpen, setModalSucursalOpen] = useState(false);
    const [sucursal, setSucursal] = useState<ISucursal|undefined>();
    const handleOpenModalSucursal = (sucursal?: ISucursal) => {
        sucursal && setSucursal(sucursal)
        setModalSucursalOpen(true)
    };
    const handleCloseModalSucursal = () => {
        refetch()
        sucursal && setSucursal(undefined)
        setModalSucursalOpen(false)
    };

    const [modalServicioOpen, setModalServicioOpen] = useState(false);
    const [servicio, setServicio] = useState<IServicio|undefined>();
    const handleOpenModalServicio = (servicio?: IServicio) => {
        servicio && setServicio(servicio)
        setModalServicioOpen(true)
    };
    const handleCloseModalServicio = () => {
        refetch()
        servicio && setServicio(undefined)
        setModalServicioOpen(false)
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (loading) {
        return <div>Cargando...</div>
    }

    const {empresa, sucursales, serviciosEmpresa} = data

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormEmpresa empresa={empresa} />
                </Grid>
                <Grid item xs={empresa.tipo === 'root' ? 6 : 12}>
                    <ListadoSucursales  sucursales={sucursales} onOpenModal={handleOpenModalSucursal} />
                </Grid>
                <Grid item xs={6}>
                    {
                        empresa.tipo === 'root' && (
                            <ListadoServicios servicios={serviciosEmpresa} onOpenModal={handleOpenModalServicio} />
                        )
                    }

                </Grid>
            </Grid>
            <>
                <SimpleModal open={modalSucursalOpen} onClose={handleCloseModalSucursal}>
                    <FormSucursal empresaId={empresa.id} sucursal={sucursal} onSaveSuccess={handleCloseModalSucursal} />
                </SimpleModal>
                <SimpleModal open={modalServicioOpen} onClose={handleCloseModalServicio}>
                    <FormServicio empresaId={empresa.id} servicio={servicio} onSaveSuccess={handleCloseModalServicio} />
                </SimpleModal>
            </>
        </>
    )
}

export default ModificarEmpresa;
