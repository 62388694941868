import React, {useEffect} from 'react';
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useMutation} from "@apollo/client";
import BusinessIcon from "@mui/icons-material/Business";
import {CREATE_EMPRESA, UPDATE_EMPRESA} from "../../../../../graphql/mutations";
import {IEmpresa} from "../../../../models";
import LinearProgressLoader from "../../../../shared/Loaders/LinearProgressLoader";


interface IFormModificarEmpresa {
    empresa?: IEmpresa;
    onSaveSuccess?: () => void;
}

const FormEmpresa = ({empresa, onSaveSuccess}: IFormModificarEmpresa) => {

    const [createEmpresa, responseCreateEmpresa] = useMutation(CREATE_EMPRESA);
    const [updateEmpresa, responseUpdateEmpresa] = useMutation(UPDATE_EMPRESA);

    const data = responseCreateEmpresa.data || responseUpdateEmpresa.data;
    const error = responseCreateEmpresa.error || responseUpdateEmpresa.error;
    const loading = responseCreateEmpresa.loading || responseUpdateEmpresa.loading;

    useEffect(() => {
        if (data) {
            onSaveSuccess && onSaveSuccess();
        }
    }, [data, onSaveSuccess])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const id = empresa?.id ?? null;
        const nombre = data.get('nombre');
        const rfc = data.get('rfc');

        if (id) {
            void updateEmpresa({
                variables: {
                    input: {
                        id,
                        nombre,
                        rfc
                    }
                }
            })
        } else {
            void createEmpresa({
                variables: {
                    input: {
                        nombre,
                        rfc
                    }
                }
            })
        }
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <BusinessIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {empresa?.nombre}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        name="nombre"
                        autoFocus
                        autoComplete='off'
                        defaultValue={empresa?.nombre}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="rfc"
                        label="RFC"
                        id="rfc"
                        autoComplete='off'
                        defaultValue={empresa?.rfc}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Guardar
                    </Button>
                </Box>
                {
                    loading && (
                        <LinearProgressLoader />
                    )
                }
                {
                    error && (
                        <Typography>
                            {error.message}
                        </Typography>
                    )
                }
            </Card>
        </>
    );
}

export default FormEmpresa;
