import {gql} from "@apollo/client";

export const UPDATE_EMPRESA = gql`
    mutation UpdateEmpresa($input: UpdateEmpresaInput!) {
        updateEmpresa(input: $input) {
            id
            nombre
            tipo
            rfc
            activa
        }
    }
`

export const CREATE_EMPRESA = gql`
    mutation CrearEmpresa($input: CreateEmpresaInput!) {
        crearEmpresa(input: $input) {
            id
            nombre
            tipo
            rfc
            activa
        }
    }
`

export const CREATE_SUCURSAL = gql`
    mutation AgregarSucursal($input: CreateSucursalInput!) {
        agregarSucursal(input: $input) {
            id
            nombre
            ubicacion
            activa
        }
    }
`

export const UPDATE_SUCURSAL = gql`
    mutation ModificarSucursal($input: UpdateSucursalInput!) {
        modificarSucursal(input: $input) {
            id
            nombre
            ubicacion
            activa
        }
    }
`

export const ACTIVAR_DESACTIVAR_SUCURSAL = gql`
    mutation ActivarDesactivarSucursal($sucursalId: String!) {
        activarDesactivarSucursal(sucursalId: $sucursalId) {
            id
            nombre
        }
    }
`

export const ALTA_SERVICIO = gql`
    mutation AltaServicio($input: AsignarServicioInput!) {
        altaServicio(input: $input) {
            id
            descripcion
        }
    }
`

export const MODIFICAR_SERVICIO = gql`
    mutation ModificarServicio($input: ModificarServicioInput!) {
        modificarServicio(input: $input) {
            id
            descripcion
        }
    }
`

export const ALTA_CLIENTE = gql`
    mutation AltaCliente($clienteId: String!, $empresaId: String!) {
        altaCliente(clienteId: $clienteId, empresaId: $empresaId) {
            id
            nombre
            tipo
            rfc
            activa
        }
    }
`

export const BAJA_CLIENTE = gql`
    mutation BajaCliente($clienteId: String!, $empresaId: String!) {
        bajaCliente(clienteId: $clienteId, empresaId: $empresaId) {
            id
            nombre
            tipo
            rfc
            activa
        }
    }
`

export const ASIGNAR_SERVICIOS_SUCURSAL = gql`
    mutation AsignarServicios($input: AsignarServiciosSucursalInput!) {
        asignarServicios(input: $input) {
            id
            nombre
            ubicacion
            activa
            servicios {
                id
                descripcion
            }
        }
    }
`

export const SINGLE_UPLOAD_MUTATION = gql`
    mutation singleUpload($input: ArchivosInput!) {
        uploadFile(input: $input)
    }
`;
