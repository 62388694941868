import React, {useState} from 'react';
import {Box, Button, TableContainer, Typography} from "@mui/material";
import styles from './TiposBitacora.module.scss';
import SimpleModal from "../shared/Modals/SimpleModal";
import FormTipoBitacora from "./components/FormTipoBitacora";
import {TipoBitacora, useFindTiposBitacoraQuery} from "../../generated/graphql";
import LinearProgressLoader from "../shared/Loaders/LinearProgressLoader";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const TiposBitacora = () => {

    const {loading, data, error, refetch} = useFindTiposBitacoraQuery();

    const [tipoBitacora, setTipoBitacora] = useState<TipoBitacora|undefined>();

    const [open, setOpen] = useState(false);
    const handleOpen = (tipoBitacora: TipoBitacora | undefined) => {
        tipoBitacora && setTipoBitacora(tipoBitacora);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setTipoBitacora(undefined);
        void refetch()
    }

    if (error) {
        return <Box>Ocurrió un error al cargar los datos</Box>
    }

    return (
        <Box>
            <Box className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Tipos de Bitácora de Registro</Typography>
                <Box>
                    <Button variant="contained" onClick={() => handleOpen(undefined)}>
                        Registrar Nuevo Tipo
                    </Button>
                </Box>
            </Box>

            <Box>
                {
                    loading && <LinearProgressLoader />
                }
            </Box>

            <Box>
                {
                    !loading && data && (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Controles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.findTiposBitacora.map((tipoBitacora) => {
                                            return (
                                                <TableRow
                                                    key={tipoBitacora.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell>
                                                        {tipoBitacora.descripcion}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => handleOpen(tipoBitacora)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </Box>

            <SimpleModal open={open} onClose={handleClose}>
                <FormTipoBitacora tipoBitacora={tipoBitacora} onClose={handleClose}/>
            </SimpleModal>
        </Box>
    );
}

export default TiposBitacora;
