import React, {useContext, useEffect, useState} from 'react'
import Box from "@mui/material/Box";

import styles from './Bitacoras.module.scss';
import {
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import SimpleModal from "../shared/Modals/SimpleModal";
import {AuthContext, IAuthContext} from "../../context/AuthContext";
import {Bitacora, useClientesQuery, useFindBitacorasLazyQuery} from "../../generated/graphql";
import FormBitacora from "./FormBitacora/FormBitacora";
import ListadoBitacoras from "./ListadoBitacoras/ListadoBitacoras";
import {useSucursales, useSucursalesField} from "../shared/hooks/sucursales";
import {DateTime} from "luxon";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";

const Bitacoras = () => {
    const { authState, isUser, isAdmin, isCalidad } = useContext<IAuthContext>(AuthContext)

    const responseClientes = useClientesQuery({
        variables: {
            empresaId: authState.userInfo!.empresaID
        }
    })

    const [desde, setDesde] = useState<string>(DateTime.now().startOf('month').toISODate());
    const [hasta, setHasta] = useState<string>(DateTime.now().endOf('month').toISODate());

    const [findBitacoras, responseBitacoras] = useFindBitacorasLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [empresaId, setEmpresaId] = useState<string>(() => {
        if (!isUser()) {
            return ''
        } else {
            return authState.userInfo!.empresaID
        }
    });
    const handleChange = (event: SelectChangeEvent) => {
        setEmpresaId(event.target.value);
    }

    const responseSucursales = useSucursales(empresaId)

    const {sucursalesSelectField, sucursalId} =
        useSucursalesField(responseSucursales, 'all', true, false, true)

    const [bitacora, setBitacora] = useState<Bitacora|undefined>()
    const [open, setOpen] = useState(false);
    const handleOpen = (bitacora: Bitacora|undefined) => {
        bitacora && setBitacora(bitacora);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
        setBitacora(undefined)
        void responseBitacoras.refetch()
    }

    const loading = responseClientes.loading;
    const error = responseClientes.error;

    useEffect(() => {
        if (empresaId) {
            void findBitacoras({
                variables: {
                    input: {
                        empresaId,
                        desde,
                        hasta,
                        sucursalId: sucursalId === 'all' ? null : sucursalId
                    }
                }
            })
        }
    }, [empresaId, desde, hasta, sucursalId, findBitacoras])

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error al cargar la página</div>;
    }

    const { clientes } = responseClientes.data!;

    return (
        <Box>
            <Box className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Seguimiento de bitácoras</Typography>
                {
                    (isAdmin() || isCalidad()) && (
                        <ButtonGroup>
                            <Button
                                component={Link}
                                variant="outlined"
                                to={"/tipos-bitacora"}
                                sx={{mr: 1}}
                            >
                                Tipos de bitácora
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => handleOpen(undefined)}
                                disabled={!empresaId}
                            >
                                Registrar Bitácora
                            </Button>
                        </ButtonGroup>
                    )
                }
            </Box>

            {
                <Box>
                    {
                        (!isUser()) && (
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="clientes-label">Clientes</InputLabel>
                                <Select
                                    labelId="clientes-label"
                                    id="clientes"
                                    value={empresaId}
                                    label="Clientes"
                                    onChange={handleChange}
                                >
                                    {
                                        clientes?.map((cliente) => (
                                            <MenuItem key={cliente.id} value={cliente.id}>{cliente.nombre}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        {sucursalesSelectField}
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="desde"
                            name="desde"
                            label="Desde"
                            type="date"
                            value={desde}
                            onChange={(event) => {setDesde(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1}}>
                        <TextField
                            required
                            id="hasta"
                            name="hasta"
                            label="Hasta"
                            type="date"
                            value={hasta}
                            onChange={(event) => {setHasta(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Box>
            }

            <Box>
                <ListadoBitacoras
                    loading={responseBitacoras.loading}
                    error={responseBitacoras.error}
                    data={responseBitacoras.data}
                    onOpenModal={handleOpen}
                    isAdmin={isAdmin}
                />
            </Box>

            <SimpleModal open={open} onClose={handleClose}>
                <FormBitacora
                    empresaId={empresaId}
                    responseSucursales={responseSucursales}
                    bitacora={bitacora}
                />
            </SimpleModal>
        </Box>
    );
}

export default Bitacoras;
