import React, {useContext, useEffect, useState} from 'react';
import {Incidente, useClientesQuery, useFindIncidentesLazyQuery} from "../../generated/graphql";
import {AuthContext, IAuthContext} from "../../context/AuthContext";
import styles from "../Facturacion/Facturacion.module.scss";
import {Box, Button, FormControl, FormControlLabel, Switch, Typography} from "@mui/material";
import ListadoIncidentes from "./ListadoIncidentes/ListadoIncidentes";
import SimpleModal from "../shared/Modals/SimpleModal";
import FormIncidente from "./FormIncidente/FormIncidente";
import {DateTime} from "luxon";
import TextField from "@mui/material/TextField";

const Incidentes = () => {

    const { authState, isAdmin, isSupervisor, isUser } = useContext<IAuthContext>(AuthContext)

    const [abiertos, setAbiertos] = useState(true)
    const [cerrados, setCerrados] = useState(false)

    const [empresaId] = useState<string>(authState.userInfo!.empresaID);

    const [desde, setDesde] = useState<string>(DateTime.now().startOf('month').toISODate());
    const [hasta, setHasta] = useState<string>(DateTime.now().endOf('month').toISODate());

    const responseClientes = useClientesQuery({
        variables: {
            empresaId
        }
    })

    const [findIncidentes, responseIncidentes] = useFindIncidentesLazyQuery({
        fetchPolicy: 'no-cache'
    });

    const [incidente, setIncidente] = useState<Incidente|undefined>();
    const [open, setOpen] = useState(false);
    const handleOpen = (incidente: Incidente|undefined) => {
        incidente && setIncidente(incidente);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
        setIncidente(undefined)
        void responseIncidentes.refetch();
    }

    useEffect(() => {
        if (!isUser() && !responseClientes.loading && responseClientes.data) {
            void findIncidentes({
                variables: {
                    input: {
                        empresasIds: responseClientes.data.clientes.map(empresa => empresa.id),
                        desde,
                        hasta,
                        abiertos,
                        cerrados
                    }
                }
            })
        }

        if (isUser() && empresaId) {
            void findIncidentes({
                variables: {
                    input: {
                        empresasIds: [empresaId],
                        desde,
                        hasta,
                        abiertos,
                        cerrados
                    }
                }
            })
        }
    }, [responseClientes.loading, responseClientes.data, abiertos, cerrados, desde, hasta, empresaId, findIncidentes, isAdmin])

    const loading = responseClientes.loading;
    const error = responseClientes.error;

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error al cargar la página</div>;
    }

    const { clientes } = responseClientes.data!;

    return (
        <Box>
            <Box className={styles.Header}>
                <Typography variant='h4' component='h1' mb={'10px'}>Seguimiento de Incidencias</Typography>
                {
                    !isSupervisor() && (
                        <Box>
                            <Button variant="contained" onClick={() => handleOpen(undefined)}>
                                Registrar Incidente
                            </Button>
                        </Box>
                    )
                }
            </Box>

            {
                <Box>
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            required
                            id="desde"
                            name="desde"
                            label="Desde"
                            type="date"
                            value={desde}
                            onChange={(event) => {setDesde(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1}}>
                        <TextField
                            required
                            id="hasta"
                            name="hasta"
                            label="Hasta"
                            type="date"
                            value={hasta}
                            onChange={(event) => {setHasta(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={abiertos}
                                onChange={() => setAbiertos(!abiertos)}
                            />
                        )}
                        label="Abiertos"
                    />
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={cerrados}
                                onChange={() => setCerrados(!cerrados)}
                            />
                        )}
                        label="Cerrados"
                    />
                </Box>
            }

            <Box>
                <ListadoIncidentes
                    loading={responseIncidentes.loading}
                    error={responseIncidentes.error}
                    data={responseIncidentes.data}
                    refetch={responseIncidentes.refetch}
                    onOpenModal={handleOpen}
                />
            </Box>

            <SimpleModal open={open} onClose={handleClose}>
                <FormIncidente
                    clientes={clientes}
                    incidente={incidente}
                />
            </SimpleModal>
        </Box>
    )
}

export default Incidentes;
